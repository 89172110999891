<script lang="ts">
	import buildInfo from 'virtual:build-info';
	import {link} from 'svelte-routing';
	import Api from '../Api';

	export let openSidebar: boolean;

	function closeSidebar() {
		openSidebar = false;
	}
</script>

{#if openSidebar}
	<button class="click-layer" on:click={closeSidebar}></button>
{/if}

<aside class="main-sidebar" class:sidebar-open={openSidebar}>
	<a href="/" class="brand-link" use:link on:click={closeSidebar} on:dblclick={() => window.location.href = '//' + Api.environment.authDomain}>
		<img width="64" height="64" src="/img/icon.png" alt="Helkor.eu Logo">
		<span>Helkor.eu</span>
	</a>

	<div class="sidebar">
		<nav class="my-2">
			<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
			<div
				class="nav"
				on:click={closeSidebar}
				on:keydown={(e) => e.key === 'Escape' && closeSidebar()}
				role="navigation"
			>
				<slot />
			</div>
		</nav>
		<span class="software">Helkor.eu Dashboard</span>
		<span class="software">Build: {new Date(buildInfo.date).toLocaleDateString()} - {buildInfo.version}</span>
	</div>
</aside>

<style>

	/* Nav */
	.nav {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		flex-direction: column;
		position: relative;
	}

	/* Sidebar */
	.sidebar {
		overflow-y: auto;
		height: calc(100% - (3.5rem + 1px));
		padding: 0 0.5rem;
		box-sizing: border-box;
		overflow-x: hidden;
	}
	@supports not (-webkit-touch-callout:none) {
		.layout-fixed .wrapper .sidebar {
		height: calc(100vh - (3.5rem + 1px));
	}}
	.my-2 {
		margin-bottom: 0.5rem;
		margin-top: 0.5rem;
		display: block;
	}

	/* Main sidebar */
	.main-sidebar {
		bottom: 0;
		float: none;
		height: 100vh;
		left: 0;
		position: fixed;
		top: 0;

		box-shadow: none !important;
		border-right: 1px solid var(--var-color-border);
		background: var(--var-color-background-dark);

		overflow-y: hidden;
		z-index: 1038;
		transition: margin-left .3s ease-in-out,width .3s ease-in-out, transform .3s ease-in-out;
		width: 250px;
		display: block;
		box-sizing: border-box;
	}

	@media all and (max-width: 1200px) and (min-width: 768px)
	{
		.main-sidebar:not(.main-sidebar:hover) {
			width: 3.6rem;
			overflow-x: hidden;
		}

		.main-sidebar:not(.main-sidebar:hover) .brand-link span {
			opacity: 0;
		}

		.software {
			opacity: 0;
		}
		.main-sidebar:not(.main-sidebar:hover) .brand-link img {
			margin-left: 0.4rem !important;
		}

		.main-sidebar:not(.main-sidebar:hover) .sidebar {
			overflow: hidden;
		}

		.nav {
			white-space: nowrap;
    		overflow: hidden;
		}
	}
	@media all and (max-width: 767px)
	{
		.main-sidebar {
			position: fixed;
			z-index: 9999;
			transform: translateX(-100%);
		}

		.sidebar-open {
			transform: translateX(0);
		}
	}

	.brand-link {
		border-bottom: 1px solid var(--var-color-background);
		text-decoration: none;
		display: block;
		font-size: 1.25rem;
		line-height: 1.5;
		padding: 0.8125rem 0.5rem;
		transition: width .3s ease-in-out;
		white-space: nowrap;
		color: white;
		opacity: 0.8;
		cursor: pointer;

	}
	.brand-link:hover {
		color: var(--var-color-primary);
	}
	.brand-link img {
		float: left;
		line-height: .8;
		margin-left: 0.8rem;
		margin-right: 0.5rem;
		margin-top: -3px;
		max-height: 33px;
		width: auto;
		height: 64px;
		transition: margin-left .2s ease-in-out;
	}
	.brand-link span {
		transition: margin-left .3s linear,opacity .3s ease,visibility .3s ease;
		font-weight: 300;
		box-sizing: border-box;
		vertical-align: middle;
	}

	.software {
		text-align: center;
		display: block;
		font-size: 0.7rem;
		color: var(--var-color-border);
	}



</style>
