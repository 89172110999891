<script lang="ts">
	const tips = [
		"Pro rychlejší práci se soubory můžete využít klávesové zkratky",
		"Některé Minecraft server listy je možné propojit s naší administrací",
		"Minecraft pluginy můžete nainstalovat přímo z našeho rozhraní",
		"Příkazem \"start\" můžete spustit vypnutý server",
		"Pozváním dalších uživatelů na Helkor můžete získat kredity",
		"Aktivace dvojfázového ověření výrazně zvyšuje bezpečnost vašeho účtu",
		"Naš VIP systém podporuje napojení vlastní domény",
		"Pokud chcete hostovat hru, která není v naší nabídce, můžete nám napsat a přidáme ji",
		"Aktualizace našeho systému probíhají skoro každý týden",
		"Naši podporu můžete kontaktovat i přes Discord",
		"Přes plánované úlohy můžete nastavit zálohování podle vašich potřeb",
		"Náš systém umí stáhnout soubory v většiny konkurenčních Minecraft hostingů"
	];

	function getRanomTip() {
		return tips[Math.floor(Math.random() * tips.length)];
	}
</script>

<div class="tip">
	<div class="tip__title">
		Veděli jste, že ...
	</div>
	<div class="tip__content">
		{getRanomTip()}
	</div>
</div>

<style>
	.tip {
		margin-top: 1rem;
		display: flex;
		gap: .25rem;
		font-size: .8rem;
		flex-wrap: wrap;
	}
	.tip__content {
		color: var(--var-color-text-light);
	}
	.tip__title {
		color: var(--var-color-text);
		font-weight: bold;
	}
</style>
