<script lang="ts">
	export let code = '';
	localStorage.setItem('referral',code);
	window.location.href = "https://helkor.eu";
</script>

<p>
	Referenční kód <b>{code}</b> zpracován.
	<br />
	Probíhá přesměrování ...
</p>
