export type MinecraftTemplate = {
	title: string;
	image: string;
	env: Record<string, string>;
	javaVersion?: string;
};

export const MinecraftTemplates: Record<string, MinecraftTemplate> = {
	'template-modpack-rpg-techpack-czech-v1.0.2-1724056777': {
		title: 'RPG TechPack Czech v1.0.2',
		image: "https://media.forgecdn.net/avatars/thumbnails/1047/162/256/256/638575399507976123.jpg",
		env: {
			MINECRAFT_JAR_ID: 'template',
			TEMPLATE_URL: 'https://storage.helkor.eu/system/installer/minecraft/modpacks/modpack-rpg-techpack-czech-v1.0.2-1724056777.tar.gz',
			SERVER_JARFILE: 'server.jar',
		},
		javaVersion: '21'
	},
	'template-modpack-majnr-plus-1722864777': {
		title: 'Majnr Plus v2',
		image: "https://media.forgecdn.net/avatars/thumbnails/922/756/256/256/638388583052574811.jpeg",
		env: {
			MINECRAFT_JAR_ID: 'template',
			TEMPLATE_URL: 'https://storage.helkor.eu/system/installer/minecraft/modpacks/modpack-majnr-plus-1722864777.tar.gz',
			JAVA_XMX_RESERVE: '1024',
			SERVER_JARFILE: 'fabric-server.jar',
		},
		javaVersion: '21'
	}
}
