<script lang="ts">
	import { Layout, setLayout } from "../layout/layout-context/layout-context";

	export let title = 'Stránka nebyla nalezena';
	export let description = 'Pravděpodobně byla zrušena, nebo jste nesprávně zadali její adresu.';
	setLayout(Layout.MAIN);
</script>

<style>
	h1 {
		text-align: center;
		margin-top: 1rem;
	}
	div {
		text-align: center;
		font-size: 10rem;
		font-weight: bold;
		color: #e5e5e5;
	}
	div span {
		color: var(--var-color-primary);
	}
	p {
		text-align: center;
		font-size: 1.2rem;
	}
	.code {
		margin-top: 6rem;
	}
</style>

<div class="code">
	4<span>0</span>4
</div>
<h1>{title}</h1>
<p>{description}</p>
