<script lang="ts">
    import { Crisp } from "crisp-sdk-web";
	import { clientJobs } from "./jobs/JobManager";
    import { ClientJobState, type ClientJobProgress, type ClientJob } from "../types/ClientJob.type";

	let jobs: ClientJob[] = [];
	clientJobs.subscribe(x => {
		jobs = x;
		Crisp.setPosition( (jobs.length > 0 ? "left" : "right") as any);
	});

	function onWindowClose(event: any) {
		if (jobs.length > 0) {
			event.preventDefault();
			event.returnValue = '';
			return "You have jobs running. Are you sure you want to close the page?";
		}
	}

	function getJobMessage(jobProgress: ClientJobProgress): string {
		if (jobProgress.message.length > 0) {
			return jobProgress.message;
		}

		const jobStateTextMap: Record<ClientJobState, string> = {
			[ClientJobState.CANCELED]: 'Zrušeno',
			[ClientJobState.FAILED]: 'Selhalo',
			[ClientJobState.FINISHED]: 'Dokončeno',
			[ClientJobState.QUEUED]: 'Ve frontě',
			[ClientJobState.RUNNING]: 'Zpracovává se',
			[ClientJobState.WAITING]: 'Čeká na zpracování',
			[ClientJobState.UNKNOWN]: 'Neznámý stav',
		};
		return jobStateTextMap[jobProgress.state];
	}
</script>

<svelte:window on:beforeunload={onWindowClose} />

<div class="job-list">
	{#each jobs as job}
		{@const progress = job.getProgress()}
		<div
			class="job"
			class:running={progress.state === 'running'}
			class:failed={['canceled', 'failed'].includes(progress.state)}
		>
			<div class="job-icon">
				{#if progress.state === ClientJobState.RUNNING}
					<i class="fas fa-spin fa-rotate"></i>
				{:else if  ['canceled', 'failed'].includes(progress.state)}
					<i class="fas fa-xmark"></i>
				{:else}
					<i class="fas fa-clock"></i>
				{/if}
			</div>
			<div class="job-state">
				<div class="job-title">
					{progress.title}
				</div>
				<div class="job-message">
					{getJobMessage(progress)}
				</div>
			</div>
			{#if progress.cancelable && ['queued', 'running', 'waiting', 'unknown'].includes(progress.state)}
				<button
					class="job-cancel"
					title="Zrušit úlohu"
					on:click|preventDefault={() => {
						job.cancel();
					}}
				>
					<i class="fas fa-times"></i>
				</button>
			{/if}
			{#if progress.percent > 0}
				<div class="job-progress-bar" style={`width: ${progress.percent}%;`}></div>
			{/if}
		</div>
	{/each}
</div>

<style>
	.job-list {
		bottom: 1rem;
		display: flex;
		flex-direction: column-reverse;
		gap: .5rem;
		position: fixed;
		right: 1rem;
	}

	.job {
		align-items: center;
		background: #2a2a2a;
		border-radius: 5px;
		display: flex;
		gap: 1rem;
		overflow: hidden;
		padding: .7rem;
		position: relative;
		width: 17rem;
	}

	.job-icon {
		color: var(--var-color-text-light);
		font-size: 1.2rem;
	}

	.job-title {
		font-size: 1.1rem;
		font-weight: bold;
	}
	.job-message {
		font-size: .9rem;
	}

	.job.running {
		background: var(--var-color-success)
	}
	.job.running .job-icon, .job.failed .job-icon {
		color: white;
	}
	.job.failed {
		background: var(--var-color-primary)
	}

	.job-progress-bar {
		background: white;
		bottom: 0;
		height: 5px;
		left: 0;
		position: absolute;
	}

	.job-cancel {
		margin-left: auto;
		margin-right: .2rem;
		color: var(--var-color-text-light);
		cursor: pointer;
		background: transparent;
		border: none;
		font-size: 1rem;
	}
	.job-cancel:hover {
		color: white;
	}
</style>
