<script lang="ts">
	export let text = "Menu";


</script>

<li class={"nav-header" + (text.length > 0 ? '' : ' hidden')}>
	{text.length > 0 ? text : '----'}
	<slot />
</li>

<style>
	.nav-header {
		padding: 1.7rem 1rem 0.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: clip;
		background-color: inherit;
		color: #d0d4db;
		font-size: .9rem;
		box-sizing: border-box;
		opacity: 1;
		transition: opacity .3s;
	}

	.hidden {
		opacity: 0;
	}

	@media all and (max-width: 1200px) and (min-width: 768px) {
		:global(.main-sidebar:not(.main-sidebar:hover)) .nav-header
		{
			opacity: 0;
		}
	}
</style>
