<script lang="ts">
  import { onDestroy } from "svelte";
	import Api from "../Api";

	// Data
	let messages: InfoboardMessage[] = [];
	let loaded = false;
	async function loadMessages() {
		const response = await Api.call('infoboard', {}, "POST", 6);
		if (response.success) {
			messages = (response.messages as InfoboardMessage[]).filter(message => message.important);

			if (messages.length > 0 && !loaded) {
				setTimeout(() => {
					if (messageContainer) {
						messageContainer.style.left = '100%';
					}
					loaded = true;
				}, 500);
			}
			if (messages.length === 0) {
				loaded = false;
			}
		}
	}
	loadMessages();
	const updateDataTimer = setInterval(loadMessages, 1000 * 30 * 1); // 10 minutes
	onDestroy(() => {
		clearInterval(updateDataTimer);
	});

	// Animation
	let messageContainer: HTMLDivElement | null = null;
	let lastTime = Date.now();
	const animationFrame = () => {
		const now = Date.now();
		const delta = now - lastTime;
		lastTime = now;

		if (!messageContainer) return;

		const currentLeft = messageContainer.offsetLeft;
		const width = messageContainer.offsetWidth;
		if (currentLeft < -width) {
			messageContainer.style.left = '100%';
		} else {
			messageContainer.style.left = `${currentLeft - delta / 10}px`;
		}

		requestAnimationFrame(animationFrame);
	}
	requestAnimationFrame(animationFrame);
</script>

<div class="infoline" class:invisible={messages.length === 0}>
	<div class="messages-wrapper" bind:this={messageContainer} class:invisible={!loaded}>
		{#each messages as message}
			{#if message.link}
				<a href="{message.link}" class="message" target="_blank">
					<span class="title title--underline">{message.title}</span> - <span class="content">{message.content}</span>
				</a>
			{:else}
			<div class="message">
				<span class="title">{message.title}</span> - <span class="content">{message.content}</span>
			</div>
			{/if}

		{/each}
	</div>
</div>

<style lang="scss">
	.infoline {
		flex: 1;
		display: inline-block;
		background: rgba(220, 46, 40, 0.5);
		border-left: .35rem solid rgba(220, 46, 40, 1);
		position: relative;
		margin: 0 1rem;
		overflow: hidden;
		height: 1.9rem;
		border-radius: 5px;
		padding: 0;
		animation: showBarAnimation .5s ease-out;
	}

	.messages-wrapper {
		text-wrap: nowrap;
		position: absolute;
		top: 0.3rem;
		display: flex;
		flex-direction: row;
		gap: 8rem;
		left: 100%;
	}

	a {
		text-decoration: none;
		color: white;
	}

	.title {
		font-weight: 600;

		&--underline {
			text-decoration: underline;
		}
	}
	.content {
		font-weight: 300;
	}

	.invisible {
		display: none;
	}

	.message {
		white-space: nowrap;
	}

	@keyframes showBarAnimation {
		0% {
			max-width: 0;
		}
		100% {
			max-width: 100%;
		}
	}
</style>
