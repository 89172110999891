export type ClientJobCallback = (progress: ClientJobProgress) => void;

export enum ClientJobState {
	QUEUED = "queued",
	RUNNING = "running",
	FINISHED = "finished",
	CANCELED = "canceled",
	FAILED = "failed",
	WAITING = "waiting",
	UNKNOWN = "unknown",
}

export interface ClientJobProgress {
	state: ClientJobState;
	percent: number;
	title: string;
	message: string;
	cancelable: boolean;
}

export interface ClientJob {
	run(): void;
	cancel(): void;
	getProgress(): ClientJobProgress;
	setOnProgressCallback(callback: ClientJobCallback): void;
}
