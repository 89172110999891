<script lang="ts">
  import { t } from "../../lib/locale";

	type PivotData = {
		order: number;
		recommended: boolean;
	}

	export let options: WithPivot<ServerEgg, PivotData>[] = [];
	export let value: number | undefined = undefined;

	const select = (id: number) => () => {
		value = id;
	};
</script>

<div class="software-list">
	{#each options.sort((a, b) => a.pivot.order - b.pivot.order) as software}
		<div class="software">
			{#if software.image}
				<img
					src={software.image}
					alt={software.name}
					draggable="false"
				/>
			{/if}
			<div class="software__infogroup">
				<div class="software__name" style={software.pivot.recommended ? 'margin-bottom:.35rem' : undefined}>
					{#if software.pivot.recommended}
						<span
							aria-label={`⭐ ${t('order.field.software.recommended')}`}
							data-balloon-pos="right"
							data-balloon-visible
						>
							{software.name}
						</span>
					{:else}
						<span>
							{software.name}
						</span>
					{/if}
				</div>
				{#if software.description}
					<div class="software__description">
						{software.description}
					</div>
				{/if}

			</div>
			<div class="software__actions">
				{#if software.id !== value}
					<button class="button" on:click={select(software.id)}>
						<i class="fa-solid fa-check"></i>
						{t('global.select')}
					</button>
				{:else}
					<button class="button success">
						<i class="fa-solid fa-check"></i>
						{t('global.selected')}
					</button>
				{/if}
			</div>
		</div>
	{/each}
</div>

<style>
	.software {
		display: flex;
		gap: 1rem;
		border-bottom: 1px solid var(--var-color-border);
		margin-bottom: 1rem;
		margin-top: 1rem;
		padding-bottom: 1rem;
	}
	.software:last-child {
		border-bottom: none;
		padding-bottom: 0;
	}
	.software img {
		width: 5rem;
		height: 5rem;
		object-fit: scale-down;
		border-radius: 5px;
	}

	.software__name {
		font-size: 1.3rem;
		font-weight: 600;
	}
	.software__description {
		color: var(--var-color-text-light);
		font-size: 400;
		font-size: .9rem;
	}

	.software__actions {
		margin-left: auto;
	}

	.button {
		display: flex;
		align-items: center;
		gap: .5rem;
		padding: .5rem 1rem;
		border-radius: 0;
		border: 1px solid var(--var-color-border);
		color: var(--var-color-text-light);
		background: transparent;
		cursor: pointer;
		text-decoration: none;
	}
	.button:hover {
		color: white;
	}

	.button.success {
		background: var(--var-color-success);
		border-color: var(--var-color-success);
		color: white;
	}

	.software-list {
		margin-top: 1.5rem;
	}

	@media (max-width: 950px) {
		.software {
			flex-wrap: wrap;
		}
		.software img {
			margin-left: auto;
			margin-right: auto;
		}
		.software__infogroup {
			width: 100%;
		}
		.software__actions {
			width: 100%;
		}
	}
</style>
