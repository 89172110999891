<script lang="ts">
	import SkeletonBlock from './SkeletonBlock.svelte';

	type Type = 'full' | 'half' | 'quarter' | 'third' | 'two-thirds' | number;

	export let width: Type = 'full';
	export let multiline: boolean = false;

	const widthIsNumeric = typeof width === 'number';
	const widthAsNumber = widthIsNumeric ? width : 0;
</script>

<div class={`skeleton-text  w-${width}`} class:multiline={multiline}>
	<div class="skeleton-placeholder">
		{#if widthIsNumeric}
			{#each Array(widthAsNumber) as _, i}
				X
			{/each}
		{:else}
			X
		{/if}
	</div>
	<div class="skeleton-content">
		<SkeletonBlock />
	</div>
</div>

<style>
	.skeleton-text {
		position: relative;
		width: min-content;

	}
	.skeleton-placeholder {
		opacity: 0;
		pointer-events: none;
	}
	.skeleton-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.w-full {
		width: 100%;
	}
	.w-half {
		width: 50%;
	}
	.w-quarter {
		width: 25%;
	}
	.w-third {
		width: 33.3333%;
	}
	.w-two-thirds {
		width: 66.6666%;
	}
	.multiline {
		margin-bottom: .3rem;
	}
</style>
