<script lang="ts">
	export let openSidebar = false;
</script>

<nav class="main-header">
	<button class="mobile-nav" on:click={()=>{
		openSidebar = !openSidebar;
	}} >
		<i class="fas fa-bars"></i>
	</button>
	<div class="navbar-nav">
		<slot />
	</div>
	<div class="navbar-nav ml-auto">
		<slot name="actions" />
	</div>
	<div class="mobile-nav ml-auto">
		<slot name="actions-mobile" />
	</div>
</nav>

<style>
	.main-header {
		transition: margin-left .3s ease-in-out;
		margin-left: 250px;
		background-color: rgb(21 22 24 / 35%);
		border-color: rgb(21 22 24 / 35%);
		backdrop-filter: blur(10px);
		box-shadow: 0 0 4px 0 rgb(0 0 0 / 60%);
		z-index: 1034;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		padding: 0.5rem 0.5rem;
		display: flex;
		box-sizing: border-box;
		position: relative;
	}
	.navbar-nav {
		display: flex;
		align-items: center;
		flex-direction: row;
		padding-left: 0;
		margin: 0;
		list-style: none;
		flex: 1;
	}
	.ml-auto {
		flex: 0;
		margin-left: auto;
	}

	@media all and (max-width: 1200px)
	{
		.main-header {
			margin-left: 3.6rem;
		}
	}

	.mobile-nav {
		display: none;
		color:white;
		padding: 0.3rem 0.5rem;
		border: none;
		font-size: 1rem;
		background-color: transparent;
		cursor: pointer;
	}

	@media all and (max-width: 768px)
	{
		.main-header {
			margin-left: 0;
		}
		.mobile-nav {
			display: block;
		}
		.navbar-nav {
			display: none;
		}
	}

</style>
