<script lang="ts">
  import { t } from "../../lib/locale";

	const sortProperties = [
		{
			id: 'name',
			title: t('order.field.product.title'),
		},
		{
			id: 'cpu',
			title: t('order.field.product.cpu'),
			icon: '/img/icon/cpu_icon.svg',
		},
		{
			id: 'memory',
			title: t('order.field.product.memory'),
			icon: '/img/icon/ram_icon.svg',
		},
		{
			id: 'disk',
			title: t('order.field.product.disk'),
			icon: '/img/icon/hdd_icon.svg',
		},
		{
			id: 'price',
			title: t('order.field.product.pricePerMonth'),
		}
	];
</script>

<div class="sort">
	{#each sortProperties as property, i}
		<div
			class="property"
			class:property--first={i === 0}
		>
			{#if property.icon}
				<img
					class="property__icon"
					alt={property.title}
					src={property.icon}
					draggable="false"
				/>
			{/if}
			<span>{property.title}</span>
		</div>
	{/each}

</div>

<style lang="scss">
	.sort {
		padding: 1.5rem;
		background-color: #151617;
		margin-bottom: .5rem;
		border-radius: 5px;
		display: flex;
		align-items: center;
		gap: 1.5rem;
		text-transform: uppercase;
		font-size: .8rem;

		.property {
			background: none;
			border: none;
			font-family: 'Oxanium', sans-serif;
			color: var(--var-color-light);
			text-transform: uppercase;
			font-weight: 500;

			flex: 0 0 7rem;
			display: flex;
			align-items: center;
			justify-content: center;


			&__icon {
				width: 1.5rem;
				height: 1.5rem;
				object-fit: scale-down;
				margin-right: .5rem;
			}

			span {
				transform: translateY(.1rem);
			}

			&--first {
				flex: 1 1 auto;
				justify-content: flex-start;
			}
		}
	}
</style>
