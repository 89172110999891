<script>
	import { t } from "../../../lib/locale";
	import LoginForm from "../../../page/LoginForm.svelte";
	import Modal from "../../Modal.svelte";
	import NavItem from "../../NavItem.svelte";
	import LayoutBase from "./LayoutBase.svelte";

	let showLogin = false;
</script>
<LayoutBase hideHeader>
	<svelte:fragment slot="sidebar">
		<NavItem
			icon="fa-solid fa-arrow-left"
			text="Zpět na web"
			href="https://helkor.eu"
			nativeLink
		/>
		<NavItem
			icon="fa-solid fa-arrow-right-to-bracket"
			text={t('login.action.login', 'Přihlásit se')}
			action={() => (showLogin = true)}
		/>
	</svelte:fragment>
	<slot />
	<Modal bind:open={showLogin}>
		<h2 style="text-align: center;">{t('login.action.login', 'Přihlásit se')}</h2>
		<LoginForm />
	</Modal>
</LayoutBase>
