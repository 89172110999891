<script lang="ts">
	import { User } from "../store";
	import type { UserRole } from "../types/Enums";
	export let roles: UserRole[] = [];
	export let invert: boolean = false;

	let hasRole: boolean;
	$: hasRole = $User ? roles.includes($User.role) : false;
</script>

{#if invert ? !hasRole : hasRole}
	<slot />
{/if}
