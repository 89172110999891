<script lang="ts">
	export let title: string = "";
	export let value = "";
	export let max: number | string | undefined = undefined;

	export let progress = 0;
	export let titleColor: string | undefined = undefined;
	export let box = false;

	export let titleOnly = false;
	export let largeTitle = false;
	export let progressUnderline = false;

	let progressStyle = "";
	let smallProgressStyle = "";

	$: progress = isNaN(progress) || !isFinite(progress) ? 0 : progress;

	const colors = [
		'#121214',
		'#121214',
		'#FFBF00',
		'#EE7714',
		'#DC2F28',
	];

	const smallProgressColors = [
		'#28a745',
		'#28a745',
		'#FFBF00',
		'#EE7714',
		'#DC2F28',
	];


	function smallProgressColor() {
		return progressColor(progress, smallProgressColors);
	}

	function progressColor(percent: number = progress, colorSpace: string[] = colors)
	{
		let divider = 1;
		for (let i = 0; i < colorSpace.length; i++)
		{
			divider /= 2;
			if ((percent ?? 0) <= 1 - divider)
			{
				return colorSpace[i];
			}
		}
		return colorSpace[colorSpace.length - 1];
	}

	$: progressStyle = box ? 'background: var(--var-color-background-dark)' : `--progress-color:${progressColor()};--progress:${(progress ?? 0)*100}%`;
	$: smallProgressStyle = box ? 'background: var(--var-color-background-dark)' : `--progress-color:${smallProgressColor()};--progress:${(progress ?? 0)*100}%`;
</script>

<div class="stat progress-bar" style={progressStyle}>
	<!--div class="icon">
		<i class="fa-solid fa-microchip"></i>
	</div-->
	<div class="info"  class:title-only={titleOnly} class:title-large={largeTitle}>
		<span class="title" style={titleColor ? `color: ${titleColor}` : undefined}>{title}</span>
		<div class="value">
			<span class="now">{value}</span>
			<span class="max">{max == undefined ? '' : '/ ' + max}</span>
		</div>
	</div>

	{#if progressUnderline}
		<div class="small-progress" style={smallProgressStyle}></div>
	{/if}
</div>

<style>
	.stat {
		box-sizing: border-box;
		padding: 1rem;
		display: flex;
		background: #0d0e0e;
		border-radius: 0;
		flex: 1 1 20rem;
		position: relative;
		overflow: hidden;
	}
	.stat:first-of-type {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	.stat:last-of-type {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	/*.icon {
		font-size: 2.5rem;
		display: flex;
		vertical-align: middle;
		justify-content: center;
		align-items: center;
		margin-right: 1rem;
		color: var(--var-color-text-light);
	}*/
	.info {
		display: flex;
		vertical-align: middle;
		justify-content: center;
		flex-direction: column;
	}

	.title {
		font-weight: 200;
		margin-bottom: 0.3rem;
		color: var(--var-color-text-light);
		transition: all 0.3s ease;
	}
	/* .title.big {
		font-weight: 400;
		font-size: 1rem;
		margin-bottom: calc(1.9rem + 6px);
	} */

	.value .now {
		font-size: 1.6rem;
		font-weight: 500;
		color: white;
		transition: all 0.3s ease;
	}
	.value .max {
		font-size: 1rem;
		margin-left: 0.3rem;
		color: var(--var-color-text-light);
	}

	.title-only .now, .title-large .now {
		font-size: 1rem;
	}
	.title-only .title, .title-large .title {
		font-size: 1.6rem;
	}

	.title-only .now {
		opacity: 0;
	}
	.title-only .title {
		transform: translateY(0.8rem);
	}

	@media all and (max-width: 1200px) {
		.stat {
			width: calc(50% - 0.5rem);
			margin-bottom: 1rem;
			border-radius: 5px;
		}
	}
	@media all and (max-width: 767px) {
		.stat {
			width: 100%;
		}
	}

	.progress-bar {
		background: linear-gradient(90deg, #121214 0, var(--progress-color) var(--progress), #0d0e0e var(--progress),#0d0e0e 100%);
	}

	.small-progress {
		position: absolute;
		background: linear-gradient(90deg, var(--progress-color) 0, var(--progress-color) var(--progress), #0d0e0e var(--progress),#0d0e0e 100%);
		width: 100%;
		height: .1rem;
		bottom: 0;
		left: 0;
	}
</style>
