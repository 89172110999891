<script lang="ts">
	export let open = false;
	export let canClose = true;
	export let showCloseButton = false;
	export let useEffect: Function = (_x: never) => { };

	export let width = undefined;

	export let close = () =>
	{
		if (canClose)
			open = false;
	};

	function onKeyDown(event: KeyboardEvent) {
		if (event.key === 'Escape' && canClose && open) {
			event.preventDefault();
			close();
		}
	}

	let style;
	$: style = width ? `width: ${width}` : '';
</script>

<svelte:window on:keydown={onKeyDown} />

{#if open}
	<div class="modal" on:mousedown|self={close} role="button" tabindex="0">
		<div {style} class="content">
			<div class="glass-box background"></div>
			<div class="box foreground" use:useEffect>
				{#if showCloseButton}
					<button class="close-button" on:click={close}>
						<i class="fas fa-times"></i>
					</button>
				{/if}
				<slot />
				<div class="clearfix"></div>
			</div>
		</div>
	</div>
{/if}

<style>
	.modal {
		background: rgba(0, 0, 0, 0.3);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;

		animation: fadeIn 0.1s ease-out;
	}
	.content {
		width: 600px;
		animation: popIn 0.1s linear;
		position: relative;
	}
	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.foreground {
		position: relative;
		background: transparent;
		border: 1px solid transparent;

		overflow-y: auto;
		max-height: 90vh;
	}

	.close-button {
		position: absolute;
		top: 0;
		right: 0;
		background: transparent;
		border: none;
		padding: 0.5rem;
		font-size: 1.5rem;
		cursor: pointer;
	}
	.close-button i {
		color: var(--var-color-text-light);
		transition: all .2s ease-in-out;
	}
	.close-button:hover i {
		color: white;
	}

	@media (max-width: 600px) {
		.content {
			width: 100%;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes popIn {
		0% {
			transform: scale(0.8);
			background: rgba(21, 22, 23, 0.5);;
			opacity: 0;
		}
		50% {
			transform: scale(1.1);
			opacity: 1;
		}
		100% {
			transform: scale(1);
			background: rgba(21, 22, 23, 0.3);
			opacity: 1;
		}
	}
</style>
