<script lang="ts">
	import { createEventDispatcher } from "svelte";

	export let checked = false;
	export let disabled = false;
	export let label = '';
	const dispatch = createEventDispatcher();

	let uuid = 'checkbox-' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

	const handleChange = () => {
		if (disabled) return;
		dispatch('change', checked);
	}
</script>

<input
	class="helkor-checkbox"
	id={uuid}
	type="checkbox"
	bind:checked
	on:change={handleChange}
	disabled={disabled}
/>
<label for={uuid} class:disabled={disabled}>
	<div class="helkor-checkbox">
		<i class="fa-solid fa-check"></i>
	</div>
	<div>{label}</div>
</label>

<style>
	.disabled {
		opacity: 0.5;
	}
</style>
