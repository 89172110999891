import { toast } from '@zerodevx/svelte-toast'

function escapeHtml(text: string): string {
	const map: Record<string, string> = {
	  '&': '&amp;',
	  '<': '&lt;',
	  '>': '&gt;',
	  '"': '&quot;',
	  "'": '&#039;'
	};

	return text.replace(/[&<>"']/g, m => map[m]);
}

export const normalTheme: Record<string, string> = {
	'--toastBackground': '#323232',
	'--toastColor': 'white',
	'--toastBarBackground': 'white',
	'--toastBorderRadius': '5px',
	'--toastMsgPadding': '1rem',
	'--toastWidth': '20rem'
};

export const internalTheme: Record<string, string> = {
	...normalTheme,
	'--toastBarBackground': '#2196f3',
};

export const successTheme: Record<string, string> = {
	...normalTheme,
	'--toastBackground': '#388e3c'
};
export const errorTheme: Record<string, string> = {
	...normalTheme,
	'--toastBackground': '#dc2f28'
};

export const success = (m: string, escape = true) => {
	toast.push(escape ? escapeHtml(m) : m,{
		duration: 3000,
		pausable: true,
		theme: successTheme,
	});
};

export const error = (m: string, escape = true) => {
	toast.push(escape ? escapeHtml(m) : m,{
		duration: 3000,
		pausable: true,
		theme: errorTheme
	});
};
