<script lang="ts">
	import { onMount, onDestroy } from "svelte";
    import Api from "../Api";
	export let value;

	let sitekey = Api.environment.debug ? "1x00000000000000000000AA" : "0x4AAAAAAAhOqQm3_NoBGkCm";

	export let captcha: HTMLDivElement | undefined;

	//turnstile.reset(captchaID);

	function errorCallback()
	{
		(globalThis as any).turnstile.reset(captcha);
	}


	let reinitTimeout: NodeJS.Timeout | null = null;

	let initCaptcha = () => {

		if (reinitTimeout)
		{
			clearTimeout(reinitTimeout);
			reinitTimeout = null;
		}

		if (!(globalThis as any).turnstile)
		{
			reinitTimeout = setTimeout(initCaptcha, 1000);
			return;
		}

		captcha = (globalThis as any).turnstile.render(captcha,{
			sitekey: sitekey,
			theme: 'dark',
			size: 'normal',
			callback: (response: CaptchaResponse) => {
				value = response;
			},
			"error-callback": errorCallback
		});
	};

	onMount(() => {
		initCaptcha();
	});

	onDestroy(()=>{
		if (reinitTimeout) {
			clearTimeout(reinitTimeout);
		}
		if (captcha) {
			(globalThis as any).turnstile.remove(captcha);
		}
	});

</script>

<div class="captcha">
	<div bind:this={captcha} ></div>
</div>

<style>
	.captcha {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 1rem 0;
		animation: showCaptcha .6s;
	}
	@keyframes showCaptcha {
		0%, 75% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
</style>
