import 'balloon-css';
import('./assets/css-global/app.css');
import('./assets/css-global/helpers.css');
import('./assets/css-global/input.css');
import('./assets/css-global/animations.css');

import { initLocale } from './lib/locale';
import buildInfo from 'virtual:build-info';
import { Crisp } from 'crisp-sdk-web';
import * as Sentry from '@sentry/svelte';
import App from './App.svelte';
import Api from './Api';
import { timeMonitor } from './lib/time-monitor';
timeMonitor.start('app-init');


// Concole info
console.log("Helkor dashboard v: " + buildInfo.version + " | Build date: " + new Date(buildInfo.date).toLocaleString() + " | Environment: " + Api.environment.name);
console.log('%cZadržte!', 'font-size: 2.7rem;color:red;text-shadow: 1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;padding: 1rem 0');
console.log('%cTato funkce prohlížeče je určena pro vývojáře. Pokud vám někdo řekl, ať sem něco zkopírujete, abyste tím aktivovali nějakou funkci Helkoru nebo hackovali něčí účet, pak byste měli vědět, že jde o podvod a že danému člověku poskytnete přístup ke svému Helkor účtu.', 'font-size: 1.1rem; padding');

// Init crisp
const CRISP_WEBSITE_ID = '23969645-bed0-4a96-b079-21d9f65c2ba3';
Crisp.configure(CRISP_WEBSITE_ID);
Crisp.setSafeMode(true);

// Init sentry
if (!Api.environment.debug) {
  Sentry.init({
    dsn: "https://c9fcfbdadfd735f5e54298127d8384a2@o4506446649098240.ingest.sentry.io/4506446650998784",
    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/dash\.helkor\.eu/
        ],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
}

initLocale();

// Init svelte app
const app = new App({
  target: document.getElementById('app')
});

export default app;
