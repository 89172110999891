import { writable } from "svelte/store";

interface GenericDialogData<T> {
	showDialog: boolean;
	data: T;
}

type ConfirmDialogCallback = (dialogResult: boolean) => void;
type ConfirmDialogType = 'confirm' | 'yesno' | 'agree' | 'ok' | ConfirmDialogCustomType;
interface ConfirmDialogCustomType {
	yes: string;
	no: string;
};
interface ConfirmDialogData {
	title: string;
	message: string;
	type: ConfirmDialogType;
	callback: ConfirmDialogCallback;
}
export interface ConfirmDialogState extends GenericDialogData<ConfirmDialogData> {};

export const ConfirmDialog = function() {
	const defaultConfirmCallback: ConfirmDialogCallback = (dialogResult: boolean) => {};
	const defaultDialogType = 'confirm' as ConfirmDialogType;
	const { subscribe, set } = writable<ConfirmDialogState>({
		showDialog: false,

		data: {
			title: '',
			message: '',
			type: defaultDialogType,
			callback: defaultConfirmCallback,
		}
	});
	let showDialog = (title: string, message: string, callback: ConfirmDialogCallback, type: ConfirmDialogType = 'confirm'): void => {
		set({
			showDialog: true,
			data: {
				title: title,
				message: message,
				callback: callback,
				type: type
			}
		});
	};
	let showDialogAsync = async (title: string, message: string, type: ConfirmDialogType = 'confirm'): Promise<boolean> => {
		return new Promise((resolve, _reject) => {
			showDialog(title, message, (dialogResult: boolean) => {
				resolve(dialogResult);
			}, type);
		});
	};

	let closeDialog = () => {
		set({
			showDialog: false,
			data: {
				title: '',
				message: '',
				callback: defaultConfirmCallback,
				type: defaultDialogType,
			}
		});
	};

	return {
		showDialog,
		showDialogAsync,
		closeDialog,
		subscribe,
	};
}();


type PromptDialogCallback = (dialogResult: string | undefined | null) => void;
type PromptDialogType = 'text' | 'number' | 'password' | 'email' | 'tel' | 'url' | 'search' | 'date' | 'time' | 'datetime-local' | 'month' | 'week' | 'color';
type PromptDialogData = {
	title: string;
	message: string;
	placeholder: string;
	value: string;
	type: PromptDialogType;
	callback: PromptDialogCallback;
}
export interface PromptDialogState extends GenericDialogData<PromptDialogData> {};

export const PromptDialog = function() {
	const defaultPromptCallback: PromptDialogCallback = (_dialogResult) => {};
	const defaultDialogType = 'text' as PromptDialogType;

	const { subscribe, set } = writable<PromptDialogState>({
		showDialog: false,

		data: {
			title: '',
			message: '',
			placeholder: '',
			value: '',
			type: defaultDialogType,
			callback: defaultPromptCallback,
		}
	});

	let showDialog = (title: string, message: string, callback: PromptDialogCallback, type: PromptDialogType = 'text', placeholder: string = '', defaultValue: string = '') => {
		set({
			showDialog: true,
			data: {
				title: title,
				message: message,
				placeholder: placeholder,
				value: defaultValue,
				callback: callback,
				type: type
			}
		});
	};

	let showDialogAsync = async (title: string, message: string, type: PromptDialogType = 'text', placeholder: string = '', defaultValue: string = ''): Promise<string | null | undefined> => {
		return new Promise((resolve, _reject) => {
			showDialog(title, message, (dialogResult) => {
				resolve(dialogResult);
			}, type, placeholder, defaultValue);
		});
	};

	let closeDialog = () => {
		set({
			showDialog: false,
			data: {
				title: '',
				message: '',
				placeholder: '',
				value: '',
				callback: defaultPromptCallback,
				type: defaultDialogType,
			}
		});
	};

	return {
		showDialog,
		showDialogAsync,
		closeDialog,
		subscribe
	};
}();
