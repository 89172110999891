interface CacheRecord<T> {
	value: T;
	expires: number;
	timeout: NodeJS.Timeout;
}

export class Cache<T> {
	private _cache: Map<string, CacheRecord<T>> = new Map();

	constructor(
		private readonly ttl: number = 1000 * 60 * 5
	) {}

	get(key: string): T | undefined {
		const record = this._cache.get(key);
		if (!record) {
			return undefined;
		}
		return record.value;
	}

	remove(key: string): void {
		const record = this._cache.get(key);
		if (record) {
			clearTimeout(record.timeout);
			this._cache.delete(key);
		}
	}

	clear(): void {
		for (const key of this._cache.keys()) {
			this.remove(key);
		}
	}

	set(key: string, value: T): void {
		const existingRecord = this._cache.get(key);
		if (existingRecord) {
			clearTimeout(existingRecord.timeout);
		}

		const record: CacheRecord<T> = {
			value,
			expires: Date.now() + this.ttl,
			timeout: setTimeout(() => this.remove(key), this.ttl)
		};
		this._cache.set(key, record);
	}
}
