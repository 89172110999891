<script lang="ts">
	import { listSoftware, listSoftwareJars } from "../../../lib/minecraft-jars";
	import Loading from "../../../layout/Loading.svelte";
	import { sortVersions } from "../../../utils/sort";
	import { MinecraftTemplates } from "./minecraft-templates";
	import OrderFormMinecraftVersionGroup from "./OrderFormMinecraftVersionGroup.svelte";

	export let env: Record<string, string> = {};

	let loading = true;
	let options: Awaited<ReturnType<typeof loadOptions>> = [];
	let selectedOption: string | null = null;
	let optionMap: Record<string, Record<string, string>> = {};

	$: {
		if (selectedOption) {
			env = optionMap[selectedOption];
		}
	}

	const priorityOverride: Record<string, number> = {
		purpur: 0,
		paper: 1,
		minecraft: 2,
	};

	async function loadOptions() {
		const jarTypes = await listSoftware();
		if (!jarTypes) {
			return;
		}

		jarTypes.software = jarTypes.software.map((software) => {
			software.info.priority = priorityOverride[software.software] ?? software.info.priority;
			return software;
		}).sort((a, b) => a.info.priority - b.info.priority);

		return await Promise.all(jarTypes.software.map(async (jarType) => {
			const versions = await listSoftwareJars(jarType.software);
			return {
				...jarType,
				jars: versions?.jars.sort((a, b) => sortVersions(b.gameVersion, a.gameVersion)) || [],
			};
		}));
	}

	loadOptions().then((result) => {
		options = result;
		optionMap = {};
		options?.forEach((option) => {
			option.jars.forEach((jar) => {
				optionMap[jar.identifier] = {
					MINECRAFT_JAR_ID: jar.identifier,
					TEMPLATE_URL: "",
				};
			});
		});
		Object.entries(MinecraftTemplates).forEach(([key, value]) => {
			if (!optionMap[key]) {
				optionMap[key] = {
					...value.env,
					MINECRAFT_JAR_ID: 'template',
				}
			}
		});

		if (Object.keys(env).includes('MINECRAFT_JAR_ID')) {
			selectedOption = Object.keys(optionMap).find((optionKey) => {
				const optionEnv = optionMap[optionKey];
				return Object.keys(env).every((envKey) => {
					return env[envKey] === optionEnv[envKey];
				});
			}) || null;
		}
		loading = false;
	});

	function getFallbackValue(activeValue: string | undefined | null, knownValues: string[]) {
		if (!activeValue || knownValues.includes(activeValue)) {
			return null;
		}
		return activeValue;
	}
</script>


{#if loading}
	<Loading />
{:else if options}
	<div class="versions">
		{#each options as option, i}
			<OrderFormMinecraftVersionGroup
				title={option.info.title}
				icon={`https://jars.helkor.eu/static/img/remotes/${option.software}.png`}
				recommended={i === 0}
				bind:value={selectedOption}
				groupIdentifier={option.software}
			>
				<option disabled value={getFallbackValue(selectedOption, option.jars.map(x => x.identifier))}>Vyberte verzi...</option>
				{#each option.jars as jar}
					<option value={jar.identifier}>{jar.shortTitle}</option>
				{/each}
			</OrderFormMinecraftVersionGroup>
		{/each}
		<OrderFormMinecraftVersionGroup
			icon={`https://jars.helkor.eu/static/img/remotes/forge.png`}
			title="Modpacky"
			groupIdentifier="template"
			bind:value={selectedOption}
		>
			<option disabled value={getFallbackValue(selectedOption, Object.keys(MinecraftTemplates))}>Vyberte modpack...</option>
			{#each Object.entries(MinecraftTemplates) as [key, value]}
				<option value={key}>{value.title}</option>
			{/each}
		</OrderFormMinecraftVersionGroup>
	</div>
{/if}

<style>
	.versions {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
		gap: 1rem;
		overflow: hidden;
		margin-top: 1.5rem;
		margin-bottom: 1rem;
	}
</style>
