import { get, writable } from "svelte/store";
import { f } from "../utils/formatString";
import { localDeviceSettings } from "./local-device-settings";

export enum Language {
	CZECH = 'cs',
	ENGLISH = 'en',
}
const DEFAULT_LOCALE = Language.CZECH;

// Translation
let dictionary: any = {};
export const localeLoaded = writable(false);

const debugMode = false;

export function translate(key: string, fallback: string = key) {
	let found = true;
	// Find translation in dictionary tree
	const keys = key.split('.');
	let translation = dictionary;
	for (const key of keys) {
		if (translation[key]) {
			translation = translation[key];
		} else {
			translation = fallback;
			found = false;
			break;
		}
	}

	if (debugMode) {
		if (!found) {
			console.warn(`Translation not found for key: ${key}`);
			return `${key}❌`;
		}
		return `${key}✅`;
	}

	return translation;
}
export function translateWithFormater(key: string, params: any, fallback: string = key) {
	const translation = translate(key, fallback);
	return f(translation, params);
}

export const t = translate;
export const tf = translateWithFormater;

// Switching locale
async function downloadLocalePack(lang: Language) {
	const url = `/lang/${lang}.json`;
	const response = await fetch(url);
	const json = await response.json();
	return json;
}

export async function initLocale() {
	let activeLang = get(localDeviceSettings).language || DEFAULT_LOCALE;
	if (!Object.values(Language).includes(activeLang as Language)) {
		activeLang = DEFAULT_LOCALE;
	}
	dictionary = await downloadLocalePack(activeLang as Language);
	localeLoaded.set(true);
}

export async function setLanguage(lang: Language) {
	localDeviceSettings.update((settings) => {
		settings.language = lang;
		return settings;
	});
	window.location.reload();
}
