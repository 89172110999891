<script lang="ts">
	import Modal from "../layout/Modal.svelte";
	import { ConfirmDialog, PromptDialog, type ConfirmDialogState, type PromptDialogState } from "../dialogs";

	// Prompt dialog
	let promptDialog: PromptDialogState;
	PromptDialog.subscribe(value => {
		if (value.showDialog) {
			setTimeout(() => {
				const inputElement = document.getElementById('prompt-dialog-input');
				inputElement?.focus();
			}, 100);
		}
		promptDialog = value
	});
	let promptDialogSubmit = () => {
		promptDialog.data.callback(promptDialog.data.value);
		PromptDialog.closeDialog();
	};
	let promptDialogCancel = () => {
		promptDialog.data.callback(undefined);
		PromptDialog.closeDialog();
	};

	// Confirm dialog
	let confirmDialog: ConfirmDialogState;
	ConfirmDialog.subscribe(x=>confirmDialog=x);

	let confirmDialogYes = () => {
		confirmDialog.data.callback(true);
		ConfirmDialog.closeDialog();
	};

	let confirmDialogNo = () => {
		confirmDialog.data.callback(false);
		ConfirmDialog.closeDialog();
	};

	let getConfirmDialogButtons = (type: typeof confirmDialog.data.type) => {
		if (typeof type === 'string') {
			const buttons = {
				confirm: ['Potvrdit', 'Zrušit'],
				yesno: ['Ano', 'Ne'],
				agree: ['Souhlasím', 'Nesouhlasím'],
				ok: ['OK'],
			}[type];
			return {
				yes: buttons[0],
				no: buttons[1],
			};
		}
		return type;
	}

	let onKeyDown = (event: KeyboardEvent) => {
		if (promptDialog.showDialog) {
			if (event.key === 'Escape') {
				event.preventDefault();
				promptDialogCancel();
			}
		}
		if (confirmDialog.showDialog) {
			if (event.key === 'Enter') {
				event.preventDefault();
				confirmDialogYes();
			}
			if (event.key === 'Escape') {
				event.preventDefault();
				confirmDialogNo();
			}
		}
	}

</script>

<svelte:window on:keydown={onKeyDown} />

<!-- Prompt dialog -->
<Modal open={promptDialog.showDialog}>
	<h2>{promptDialog.data.title}</h2>
	<p>
		{#each promptDialog.data.message.split('\n') as line}
			{line}<br />
		{/each}
	</p>
	<form on:submit|preventDefault={promptDialogSubmit}>
		{#if promptDialog.data.type == 'text'}
			<input id="prompt-dialog-input" type="text" placeholder={promptDialog.data.placeholder} bind:value={promptDialog.data.value} />
		{/if}

		<div>
			<button class="button right primary" type="submit">Potvrdit</button>
			<button class="button right secondary" on:click|preventDefault={promptDialogCancel}>Zrušit</button>
		</div>
	</form>
</Modal>

<!-- Confirm dialog -->

<Modal open={confirmDialog.showDialog} close={confirmDialogNo}>
	<h2>{confirmDialog.data.title}</h2>
	<p>
		{#each confirmDialog.data.message.split('\n') as line}
			{line}<br />
		{/each}
	</p>

	<div>
		<button on:click={confirmDialogYes} class="button right">{getConfirmDialogButtons(confirmDialog.data.type).yes}</button>

		{#if confirmDialog.data.type !== 'ok'}
		<button on:click={confirmDialogNo} class="button right secondary">{getConfirmDialogButtons(confirmDialog.data.type).no}</button>
		{/if}
	</div>
</Modal>
