/** Dispatch event on click outside of node */
type OnClickOutsideCallback = ((event: CustomEvent) => void) | (() => void);
export function clickOutside(node: Node, fn: OnClickOutsideCallback | undefined = undefined) {
	const handleClick = (event: Event) => {
		if (node && !node.contains(event.target as Node) && !event.defaultPrevented) {
			if (fn) {
				fn(new CustomEvent('click_outside', node as any));
			} else {
				node.dispatchEvent(
					new CustomEvent('click_outside', node as any)
				);
			}
		}
	};

	document.addEventListener('click', handleClick, true);

	return {
		destroy() {
			document.removeEventListener('click', handleClick, true);
		}
	}
}
