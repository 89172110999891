<script lang="ts">
	export let videoSource: string | undefined = undefined;
	export let title: string = "Pracuji ...";
	if (!videoSource) {
		videoSource = "/video/construction.mp4";
	}
</script>

<div class="boot-up">
	<video class="boot-up-video" controls={false} autoplay={true} loop={true}>
		<source src={videoSource} type="video/mp4" />
		<track kind="captions" />
	</video>
	<div class="boot-up-progress">
		<div></div>
	</div>
	<div class="boot-up-title">
		{title}
	</div>
</div>

<style>
	.boot-up {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		gap: 1rem;
	}
	.boot-up-progress {
		height: .3rem;
		border-radius: .5rem;
		max-width: 100%;
		width: 15rem;
		background: rgb(10, 10, 10);
		overflow: hidden;
	}
	.boot-up-progress div {
		height: 100%;
		width: 0%;
		background: var(--var-color-primary);
		animation: boot-up-progress 1s ease-in-out infinite alternate;
	}
	@keyframes boot-up-progress {
		0% {
			margin-left: 0%;
			width: 25%;
			animation-timing-function: ease-in;
		}
		50% {
			margin-left: 25%;
			width: 50%;
			animation-timing-function: ease-out;
		}
		100% {
			margin-left: 75%;
			width: 25%;
		}
	}

	.boot-up-title {
		color: var(--var-color-text-light)
	}

	.boot-up-video {
		width: 20rem;
		max-width: 100%;
	}
</style>
