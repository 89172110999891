<div class="skeleton">
</div>

<style>
	.skeleton {
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, rgba(100, 100, 100, .1) 0%, rgba(255, 255, 255, 0.05) 40%, rgba(100, 100, 100, .1) 75%, rgba(100, 100, 100, .1) 100%);
		background-size: 200% 100%;
		animation: skeleton 1s infinite linear;
		border-radius: 5px;
	}

	@keyframes skeleton {
		0% {
			background-position: 100% 0;
		}

		100% {
			background-position: -100% 0;
		}
	}
</style>
