<script lang="ts">
	import { link } from 'svelte-routing';
	import { success, error } from '../toaster';
	import Api from '../Api';

	let loading = false;
	let email = "";
	function submitRecovery()
	{
		loading = true;
		Api.call("auth/recovery/password",{
			email: email
		})
		.then(response=>{
			if (response.success) {
				success("Na váš email byl odeslán odkaz pro obnovení hesla.");

			} else {
				error(response.message ?? "Nastala neočekávaná chyba");
			}
			loading = false;
			email = '';
		})
		.catch(e=>{
			error("Nastala neočekávaná chyba");
			loading = false;
			email = '';
		});
	}

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const backUrl = urlParams.get('back');

	document.title = 'Zapomenuté heslo - Helkor';
</script>

<p class="align-center">
	Zapomněli jste heslo?
	<br />
	Zadejte svůj e-mail na který vám odešleme instrukce.
</p>

<form on:submit|preventDefault={submitRecovery}>
	<div class="input-group">
		<div class="input-group-prepend">
			<i class="fas fa-envelope"></i>
		</div>
		<input type="email" placeholder="E-mail" bind:value={email} required />
	</div>
	<div class="half">
		<br />
		<a href={backUrl ?? '/login'} use:link>Přihlásit se</a>
	</div>
	<div class="half">
		{#if loading}
		<button class="button" disabled>
			<i class="fa-solid fa-circle-notch fa-spin"></i>
		</button>
		{:else}
		<input type="submit" value="Obnovit heslo" />

		{/if}
	</div>
	<div class="clearfix"></div>
</form>

<style>
	.half {
		float: left;
		width: 50%;
	}

	.half a {
		text-decoration: none;
		margin: 0.4rem 0;
		font-size: .9rem;
		font-weight: bold;
	}

	input, .input-group-prepend, .button {
		border: 1px solid transparent;
	}
</style>
