import { derived, get, writable } from "svelte/store";
import Api from "./Api";
import { success } from "./toaster";
import ProfilePictureManager from "./lib/ProfilePictureManager";
import { Crisp } from "crisp-sdk-web";
import Cookies from "js-cookie";
import type { ServerFeatureFlags } from "./types/Enums";
import { hasFlag } from "./utils/flag-utils";
import { timeMonitor } from "./lib/time-monitor";

// User
export const UserLoading = writable(false);
export const User = function() {
	const { subscribe, set} = writable<User | undefined>(undefined);

	let gravatar = (user: User) => {
		user.avatar = ProfilePictureManager.getAvatar(user);
		return user;
	};

	let invalidation = Date.now();

	let fetch = async () => {
		const requestTime = Date.now();

		let token = Api.getToken();
		if(token === undefined) {
			set(undefined);
			return undefined;
		}

		UserLoading.set(true);
		let response = await Api.call('whoami',undefined,'GET');

		if (requestTime < invalidation) {
			UserLoading.set(false);
			return;
		}

		UserLoading.set(false);
		if (!response.success) {
			set(undefined);
			Crisp.session.setData({
				user_id: undefined,
				role: undefined,
			});
			return;
		}
		const user = gravatar(response.user);
		set(user);

		if (!localStorage.getItem('sudo_token')) {
			Crisp.setTokenId(response.user.id + '.' + response.user.identifier);
			Crisp.session.setData({
				url: `https://dash.helkor.eu/admin/users/edit/${response.user.id}`,
				role: response.user.role,
				credit: Math.round(response.user.credits),
				verified: response.user.email_verified ? 'Yes' : 'No',
			});
			Crisp.user.setAvatar(user.avatar!);
			Crisp.user.setNickname(response.user.username);
			Crisp.user.setEmail(response.user.email);
		}

	};

	if (Api.getToken() !== null)
	{
		fetch();
	}

	return {
		subscribe,
		set: (user: any) =>{
			set(gravatar(user));

		},
		fetch,
		logout: () => {
			invalidation = Date.now();
			if (localStorage.getItem('sudo_token'))
			{
				Api.call('auth/logout');
				localStorage.removeItem('sudo_token');
				fetch();
				success("SUDO relace ukončena");
			}
			else
			{
				Api.call('auth/logout');
				Cookies.remove('auth_token');
				set(undefined);
				success("Úspěšně odhlášen<br /><b>Měj se</b>", false);
				Crisp.session.pushEvent('signout');
			}
		}
	}
}();

// Active server
export const ActiveServer = function(){
	const activeServerStore = writable<Server | undefined>(undefined)
	const { subscribe, set, update } = activeServerStore;

	let oldId: ServerIdentifier | null | undefined;

	let forceFetch = async (id: ServerIdentifier) => {
		timeMonitor.start('fetch-active-server');
		try {
			const response = await Api.call('server/' + id + '/detail')
			if (!response.success) {
				set(undefined);
				return;
			}

			oldId = response.container.is_suspended ? undefined : id;
			set({
				meta: response.server,
				container: response.container
			});

		} catch (_err: unknown) {
			set(undefined);
		} finally {
			timeMonitor.stop('fetch-active-server');
		}
	};

	return {
		subscribe,
		set: (x: any | undefined) => {
			oldId = x === undefined ? undefined : oldId;
			set(x);
		},

		forceFetch,
		fetch: async (id: ServerIdentifier) => {
			if (id === oldId) return;
			oldId = id;
			await forceFetch(id);
		},

		unsafeUpdate: update,

		hasFeature: (feature: ServerFeatureFlags) => {
			return hasFlag(get(activeServerStore)?.meta.feature_flags ?? 0, feature);
		}
	}
}();
