<script lang="ts">
	import Captcha from "../lib/Captcha.svelte";
	import { link, navigate } from 'svelte-routing';
	import { success, error } from '../toaster';
	import Api from "../Api";
    import { ConfirmDialog } from "../dialogs";
	import { User } from "../store";

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const backUrl = urlParams.get('back');

	let username = "";
	let email = "";
	let password = "";
	let passwordAgain = "";

	let captchaResponse: CaptchaResponse | null = null;
	let captcha: HTMLDivElement | undefined;

	let agree = false;
	let loading = false;

	let referral = localStorage.getItem('referral') ?? null;

	function submitRegister()
	{
		if (Api.environment.debug) {
			alert('V testovacím prostředí nelze vytvářet účty');
			return;
		}

		if (username.length == 0 || email.length == 0 || password.length == 0 || passwordAgain.length == 0)
		{
			error("Vyplňte prosím všechna pole");
			return;
		}
		if (password != passwordAgain)
		{
			error("Hesla se neshodují");
			password = "";
			passwordAgain = "";
			return;
		}
		if (captchaResponse == null)
		{
			error("Prokažte prosím, že nejste robot");
			return;
		}
		if (!agree)
		{
			error("Musíte souhlasit s podmínkami");
			return;
		}

		loading = true;
		Api.call('user/create', {
			username: username,
			email: email,
			password: password,
			captcha: captchaResponse,
			agree: agree,
			referral: referral ?? null,
		})
		.then(response=>{
			if (response.success) {
				resetForm();
				success("Registrace proběhla úspěšně");

				Api.setToken(response.token ?? undefined);
				User.fetch();

				if ((globalThis as any).PasswordCredential && response.user)
				{
					const cred = new (globalThis as any).PasswordCredential({
						id: email,
						password: password,
						name: username,
						icon: "https://helkor.eu/img/favicon.png"
					});
					globalThis.navigator.credentials.store(cred);
				}

				navigate(backUrl ?? '/');
			} else {
				error(response.message ?? 'Nastala neočekávaná chyba');
				password = "";
				passwordAgain = "";
				loading = false;
			}
		})
		.catch(error=>{
			error("Něco se pokazilo");
			resetForm();
		});
	}

	function resetForm() {
		loading = false;
		username = "";
		email = "";
		password = "";
		passwordAgain = "";
		agree = false;

		(globalThis as any).turnstile.reset(captcha);
	}

	function deleteReferral() {
		ConfirmDialog.showDialog('Odebrat doporučení?','Tato akce je nevratná a uživatel, který Vám náš hosting doporučil nedostane žádný bonus. Opravdu chcete doporučení odebrat?', (result: boolean) => {
			if (!result) return;

			localStorage.removeItem('referral');
			referral = null;
		},'yesno');
	}
</script>
<p class="align-center">Nemáte ještě Helkor účet? Vytvořte si ho!</p>

<form on:submit|preventDefault={submitRegister}>
	<div class="input-group">
		<div class="input-group-prepend">
			<i class="fas fa-user"></i>
		</div>
		<input type="text" placeholder="Uživatelské jméno" minlength={3} required bind:value={username} />
	</div>
	<div class="input-group">
		<div class="input-group-prepend">
			<i class="fas fa-envelope"></i>
		</div>
		<input type="email" placeholder="E-mail" required bind:value={email} />
	</div>
	<div class="input-group">
		<div class="input-group-prepend">
			<i class="fas fa-lock"></i>
		</div>
		<input type="password" placeholder="Heslo" minlength={6} required bind:value={password} />
	</div>
	<div class="input-group">
		<div class="input-group-prepend">
			<i class="fas fa-lock"></i>
		</div>
		<input type="password" placeholder="Zopakovat heslo" minlength={6} required bind:value={passwordAgain} />
	</div>

	{#if referral !== null}
		<span class="ref-title">Kód doporučení:</span>
		<div class="input-group">
			<div class="input-group-prepend">
				<i class="fa-solid fa-thumbs-up"></i>
			</div>
			<input type="text" placeholder="Doppručení" value={referral} readonly />
			<button
				class="input-group-append ref-trash"
				on:click={deleteReferral}
			>
				<i class="fa-solid fa-trash"></i>
			</button>
		</div>
		<span class="ref-info">
			Uvedením kódu doporučení podpoříte uživatele, který Vám Helkor doporučil.
			<br />
			Pokud ho z nějakého důvodu podpořit nechcete, doporučení můžete odebrat.
		</span>
	{/if}

	<Captcha bind:value={captchaResponse} bind:captcha={captcha} />

	<input id="tos_agree" class="helkor-checkbox" type="checkbox" required bind:checked={agree} />
	<label for="tos_agree">
		<div class="helkor-checkbox">
			<i class="fas fa-check"></i>
		</div>
		Souhlasím s <a href="https://helkor.eu/tos" target="_blank">VOP</a> a <a href="https://helkor.eu/gdpr" target="_blank">GDPR</a>.
	</label>

	<div class="clearfix"></div>

	<div class="half">
		<br />
		<a href={backUrl ?? '/login'} use:link>Už mám účet</a>
	</div>

	<div class="half">
		{#if loading}
			<button class="button" disabled>
				<i class="fas fa-circle-notch fa-spin"></i>
			</button>
		{:else}
			<input type="submit" value="Registrovat" />
		{/if}
	</div>

	<div class="clearfix"></div>

</form>

<style>
	.half {
		width: 50%;
		float: left;
	}

	.half a {
		text-decoration: none;
		margin: 0.4rem 0;
		font-size: .9rem;
		font-weight: bold;
	}

	.ref-title {
		display: block;
		margin-top: 0.7rem;
	}
	.ref-info {
		font-size: 0.8rem;
		color: var(--var-color-text-light);
	}
	.ref-trash {
		color: var(--var-color-text-light);
		cursor: pointer;
	}
	.ref-trash:hover {
		color: var(--var-color-primary);
	}

	input, .input-group-prepend, .input-group-append, .button {
		border: 1px solid transparent;
	}
</style>
