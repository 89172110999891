<script lang="ts">
  import Loading from "../../layout/Loading.svelte";

	interface ChangelogRecord {
		timestamp: string;
		title: string;
		content: string;
	}

	let loading = false;
	let changelogs: ChangelogRecord[] = [];

	fetch("https://changelog.helkor.eu/feed.json")
	.then(response => response.json())
	.then(data => {
		lastTitle = '';
		changelogs = data.sort((a: ChangelogRecord, b: ChangelogRecord) => {
			return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
		}).splice(0, 10);
	})
	.catch(error => {
		console.error("Failed to fetch changelog", error);
	})
	.finally(() => {
		loading = false;
	});

	function parseChangelog(content: string): string {
		return content
			.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
			.replace(/\*(.*?)\*/g, "<em>$1</em>")
			.replace(/`(.*?)`/g, "<code>$1</code>")
			.replace(/\n/g, "<br>")
			.replace(/\[(.+?)\]\(([^\s]+)\)/g, "<a href=\"$2\" target=\"_blank\">$1</a>")
			.replace(/(Insider)/g, "<span class=\"tag tag--insider\" >$1</span>")
			.replace(/(Beta)/g, "<span class=\"tag tag--beta\" >$1</span>");
	}

	let lastTitle = "";
</script>

<div class="changelogs">
	{#if loading}
		<Loading />
	{:else}
		{#each changelogs as log}
			<div class="changelogs__title">
				{log.title}
			</div>
			<div>
				{@html parseChangelog(log.content)}
			</div>
		{/each}
	{/if}

	<a class="button small secondary" target="_blank" href="https://changelog.helkor.eu">
		Otevřít seznam všech změn
		<i class="fas fa-external-link-alt right"></i>
	</a>
</div>

<style>
	.changelogs {
		max-height: 20rem;
		overflow-y: auto;
	}

	.changelogs__title {
		margin-top: 1rem;
		margin-bottom: .25rem;
		color: var(--var-color-text-light);
		font-weight: 400;
		font-size: .8rem;
	}

	.button {
		margin-top: 1rem;
	}

	.changelogs :global(.tag.tag--insider) {
		background: linear-gradient(90deg, #f44336, #ff9800);
		color: transparent;
		background-clip: text;
		font-weight: 600;
	}
	.changelogs :global(.tag.tag--beta) {
		background: linear-gradient(90deg, #4CAF50, #CDDC39);
		color: transparent;
		background-clip: text;
		font-weight: 600;
	}
</style>
