export function hasFlag(flags: number, flag: number): boolean {
	return (flags & flag) === flag;
}
export function hasFlagAny(flags: number, ...flag: number[]): boolean {
	return flag.some(f => hasFlag(flags, f));
}
export function hasFlagAll(flags: number, ...flag: number[]): boolean {
	return flag.every(f => hasFlag(flags, f));
}
export function setFlag(flags: number, flag: number): number {
	return flags | flag;
}
export function unsetFlag(flags: number, flag: number): number {
	return flags & ~flag;
}
export function toggleFlag(flags: number, flag: number): number {
	return flags ^ flag;
}
export function setFlagTo(flags: number, flag: number, value: boolean): number {
	return value ? setFlag(flags, flag) : unsetFlag(flags, flag);
}
