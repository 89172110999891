export function formatBytes(bytes: number, decimals = 2, k = 1024) {
	if (bytes === 0) return '0 Bytes';

	//const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes =
		k === 1024 ?
			['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'] :
			['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatPercent(percent: number, decimals = 2) {
	return parseFloat((percent * 100).toFixed(decimals)) + '%';
}

export function formatCpuLoad(load: number) {
	const loadString = String(parseFloat(load.toFixed(2)));

	const split = loadString.split('.');
	if (split.length === 1)
	{
		return split[0] + '.' + '00';
	}

	while (split[split.length - 1].length < 2)
	{
		split[split.length - 1] += '0';
	}

	return split.join('.') + '%';
}

interface FormatTimespanArgs {
	days: boolean;
	hours: boolean;
	minutes: boolean;
	seconds: boolean;
}

export function formatTimespan(seconds: number, display: FormatTimespanArgs = { days: true, hours: true, minutes: true, seconds: true }) {
	let minutes = Math.floor(seconds / 60);
	let hours = Math.floor(minutes / 60);
	let days = Math.floor(hours / 24);

	let result = '';

	if (days > 0 && display.days)
		result += days + 'd ';
	if (hours > 0 && display.hours)
		result += (hours % 24) + 'h ';
	if (minutes > 0 && display.minutes)
		result += (minutes % 60) + 'm ';

	if (display.seconds)
		result += (seconds % 60) + 's' ;

	return result;
}

const currencyFormat: Record<string, { prefix: string, suffix: string }> = {
	czk: {
		prefix: '',
		suffix: ' Kč',
	},
	eur: {
		prefix: '',
		suffix: '€'
	}
};

export function formatCurrency(value: number, currency: string) {
	const config = currencyFormat[currency] ?? {
		prefix: '',
		suffix: '',
	};

	let str = value.toFixed(2);
	var parts = str.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	str = parts.join(".");

	return `${config.prefix}${str}${config.suffix}`;
}

export function formatPlural(count: number, one: string, few: string, many: string, zero: string | undefined = undefined): string {
	if (count === 0 && zero) return zero;
	if (count === 1) return one;
	if (count >= 2 && count <= 4) return few;
	return many;
}

export function removeDiactrics(str: string) {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function formatNumber(value: number, decimals = 0) {
	const [real, decimal] = value.toFixed(decimals).split('.');
	const realPart = real.split('').reverse().join('').match(/.{1,3}/g)?.join(' ').split('').reverse().join('') ?? '';
	return realPart + (decimal ? `,${decimal}` : "");
}
