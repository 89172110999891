<script lang="ts">
	import { Layout } from "../layout/layout-context/layout-context";
	import DynamicRouteArray from "../lib/DynamicRouteArray.svelte";
	const routes = [
		['/admin', [
			['/categories', [
				['/edit/:id', () => import('./page/CategoryEditPage.svelte')],
				['/new', () => import('./page/CategoryEditPage.svelte')],
				['', () => import('./page/CategoryListPage.svelte')],
			]],
			['/products', [
				['/edit/:id', () => import('./page/ProductEditPage.svelte')],
				['/new', () => import('./page/ProductEditPage.svelte')],
				['', () => import('./page/ProductListPage.svelte')],
			]],
			['/eggs', [
				['/edit/:id', () => import('./page/EggEditPage.svelte')],
				['', () => import('./page/EggListPage.svelte')],
			]],
			['/users', [
				['/edit/:id', () => import('./page/UserEditPage.svelte')],
				['', () => import('./page/UserListPage.svelte')],
			]],
			['/server', [
				['/:id', () => import('./page/ServerAdminEditPage.svelte')],
				['s', () => import('./page/ServerListPage.svelte')],
			]],
			['/payments', [
				['/detail/:id', () => import('./page/payments/PaymentDetail.svelte')],
				['', () => import('./page/payments/PaymentListPage.svelte')],
			]],
			['/log', [
				['/:id', () => import('./page/LogDetailPage.svelte')],
				['', () => import('./page/LogPage.svelte')],
			]],
			['/utils', () => import('./page/AdminUtilsPage.svelte')],
			['/marketplace', [
				['', () => import('./page/MarketplaceList.svelte')],
			]]

		], Layout.MAIN],
	] as RouterRecord[];
</script>
<DynamicRouteArray {routes} />
