import { writable } from "svelte/store";

export enum Layout {
	NONE = 'empty',
	MAIN = 'main',
	LOGIN = 'login',
	ANONYMOUS = 'anonymous',
}

export const currentLayout = writable<Layout>(Layout.NONE);

export const setLayout = (layout: Layout) => {
	currentLayout.update(() => {
		return layout;
	});
};
