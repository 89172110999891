<script lang="ts">
	import { formatBytes } from "../../formater";
  import { t } from "../../lib/locale";

	export let packageName: string = "-";
	export let packagePrice: number = 0;
	export let packageCpu: number = 0;
	export let packageRam: number = 0;
	export let packageDisk: number = 0;

	export let serverName: string = "-";
	export let serverNodeName: string = "";
	export let serverIsTesting: boolean = false;
	export let serverSoftware: string = "";

	export let creditPrice: number = 0;

	export let error: string | undefined = undefined;
	export let submit: () => void;
</script>

<div class="order-overview">
	<h2>{t('order.summary.title')}</h2>

	{#if packagePrice >= 0}
		<h3 class="item-group-title">{t('order.summary.monthly')}</h3>
		<div class="item">
			<div class="item__info">
				<div class="item__title">
					{packageName}
				</div>
				<div class="item__params">
					{#if packageCpu}
						<div class="param">
							<div>{t('order.field.product.cpu')}:</div>
							<div>{packageCpu / 100}x</div>
						</div>
					{/if}
					{#if packageRam}
						<div class="param">
							<div>{t('order.field.product.memory')}:</div>
							<div>{formatBytes(packageRam * 1024 * 1024)}</div>
						</div>
					{/if}
					{#if packageDisk}
						<div class="param">
							<div>{t('order.field.product.disk')}:</div>
							<div>{formatBytes(packageDisk * 1000 * 1000, 0, 1000)}</div>
						</div>
					{/if}
					{#if serverName}
						<div class="param">
							<div>{t('order.field.server.name.title')}:</div>
							<div>{serverName}</div>
						</div>
					{/if}
					{#if serverSoftware}
						<div class="param">
							<div>{t('order.field.software.title')}:</div>
							<div>{serverSoftware}</div>
						</div>
					{/if}
					{#if serverNodeName}
						<div class="param">
							<div>{t('order.field.node.title')}:</div>
							<div>{serverNodeName}</div>
						</div>
					{/if}
					{#if serverIsTesting}
						<div class="param">
							<div>Předplaceno:</div>
							<div>3 dny</div>
						</div>
					{/if}
				</div>
			</div>
			<div class="item__price">
				<div>{packagePrice} Kč</div>
				<div>{t('order.field.product.perMonth')}</div>
			</div>
		</div>

		{#if creditPrice > 0}
			<h3 class="item-group-title">{t('order.summary.onetime')}</h3>
			<div class="item">
				<div class="item__info">
					<div class="item__title">
						Dobití kreditu
					</div>
				</div>
				<div class="item__price">
					<div>
						{creditPrice} Kč
					</div>
				</div>
			</div>
		{/if}

		<button
			class="button success button--order"
			disabled={!!error}
			data-balloon-pos="up"
			aria-label={error}
			on:click={submit}
		>
			<i class="fas fa-check"></i>
			{t('order.submit')}
		</button>
	{/if}
</div>

<style>
	.order-overview {
		position: sticky;
		margin-top: 1.5rem;
		top: 1rem;
		background-color: var(--var-color-border);
		border-radius: 5px;
		padding: .5rem 1.5rem;
	}

	.item {
		display: flex;
		justify-content: space-between;
	}

	.item-group-title {
		margin-bottom: .25rem;
	}
	.item__title {
		font-size: 1rem;
		font-weight: 500;
	}
	.item__params {
		display: flex;
		flex-direction: column;
		gap: .5rem;
		margin-top: .5rem;
		width: 100%;
	}

	.param {
		color: var(--var-color-text-light);
	}

	.item__price {
		color: var(--var-color-text-light);
		text-align: right;
	}
	.item__price div:first-child {
		font-size: 1rem;
		color: white;
	}

	.button--order {
		margin-top: 1rem;
	}

</style>
