export enum UserRole {
	MEMBER = 'member',
	CLIENT = 'client',
	INSIDER = 'insider',
	ADMIN = 'admin',
}

export const UserRoleNumeric: Record<UserRole, number> = {
	[UserRole.MEMBER]: 1,
	[UserRole.CLIENT]: 2,
	[UserRole.INSIDER]: 3,
	[UserRole.ADMIN]: 100,
};

// This enum is flag enum,
// do not change numeric values once assigned!
export enum ServerFeatureFlags {
	FLAG_KEEP_ON_SYNC = 1 << 0, // When set server will not synchronize flags with product and egg

	EULA			= 1 << 1, // DEPRECATED MINECRAFT - Use MINECRAFT instead - Will be removed in future when all servers are migrated to new system
	JAVA_VERSION	= 1 << 2, // DEPRECATED MINECRAFT - Use MINECRAFT instead - Will be removed in future when all servers are migrated to new system
	MINECRAFT		= 1 << 3,
	PID_LIMIT		= 1 << 4,
	STRAM_DISK_SIZE	= 1 << 5,

	MINECRAFT_PLUGIN_INSTALLER	= 1 << 6, // DEPRECATED MINECRAFT - Use MINECRAFT instead - Will be removed in future when all servers are migrated to new system
	MINECRAFT_SERVER_LIST		= 1 << 7, // DEPRECATED MINECRAFT - Use MINECRAFT instead - Will be removed in future when all servers are migrated to new system
	// = 1 << 8 // UNUSED since 2024-07-25
	MARKETPLACE = 1 << 9,
	MINECRAFT_PROPERTIES 		= 1 << 10, // DEPRECATED MINECRAFT - Use MINECRAFT instead - Will be removed in future when all servers are migrated to new system

}

export const ServerFeatureFlagNames: Record<ServerFeatureFlags, string> = {
	[ServerFeatureFlags.FLAG_KEEP_ON_SYNC]: 'Override flags',
	[ServerFeatureFlags.EULA]: 'EULA [zastaralé]',
	[ServerFeatureFlags.JAVA_VERSION]: 'Java Verze [zastaralé]',
	[ServerFeatureFlags.MINECRAFT]: 'Minecraft [new egg only]',
	[ServerFeatureFlags.PID_LIMIT]: 'PID Limiter',
	[ServerFeatureFlags.STRAM_DISK_SIZE]: 'Steam Disk Size',
	[ServerFeatureFlags.MINECRAFT_PLUGIN_INSTALLER]: 'Minecraft Plugin Installer [zastaralé]',
	[ServerFeatureFlags.MINECRAFT_SERVER_LIST]: 'Minecraft Server List [zastaralé]',
	[ServerFeatureFlags.MARKETPLACE]: 'Marketplace (VIP systém)',
	[ServerFeatureFlags.MINECRAFT_PROPERTIES]: 'Minecraft properties editor [zastaralé]',
};
