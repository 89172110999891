import { Cache } from "./cache";

const BASE_URL = "https://jars.helkor.eu/api/v1";
const jarsCache = new Cache<any>(1000 * 60 * 60);


export interface SoftwareInfo {
	title: string;
	description: string;
	software: string;
	priority: number;
}

export interface Software {
	software: string;
	info: SoftwareInfo;
	url: string;
}

export interface SoftwareGroup {
	group: string;
	url: string;
}

export interface Jar {
	identifier: string;
	title: string;
	shortTitle: string;
	software: string;
	gameVersion: string;
	javaVersion?: string;
	remoteUrl: string;
	localPath?: string;
	stable: boolean;
	createdAt: string;
	updatedAt: string;
	fileName: string;
	postInstall?: string;
	jarFileName?: string;
	downloadCount: number;
	bestDownload: string;
	downloaded: boolean;
}

async function get<T>(endpoint: string): Promise<T|null> {
	const cached = jarsCache.get(endpoint);
	if (cached) {
		return cached;
	}

	try {
		const response = await fetch(`${BASE_URL}/${endpoint}`);
		const data = await response.json();

		if (data.error) {
			return null;
		}

		jarsCache.set(endpoint, data);
		return data as T;
	}
	catch (error) {
		console.error(error);
		return null;
	}

}

interface ListSoftwareResponse {
	software: Software[];
}
export async function listSoftware() {
	return get<ListSoftwareResponse>('software');
}

interface GetSoftwareResponse {
	software: string;
	info: SoftwareInfo;
	jars: string;
	groups: SoftwareGroup[];
}
export async function getSoftware(software: string) {
	return get<GetSoftwareResponse>(`software/${software}`);
}

interface ListSoftwareJarsResponse {
	software: string;
	jars: Jar[];
}

export async function listSoftwareJars(software: string)  {
	return get<ListSoftwareJarsResponse>(`software/${software}/jars`);
}

export async function getSoftwareGroup(software: string, group: string) {
	return get<{ software: string, jars: string}>(`software/${software}/group/${group}`);
}

export async function listSoftwareGroupJars(software: string, group: string) {
	return get<ListSoftwareJarsResponse & { group: string }>(`software/${software}/group/${group}/jars`);
}

export async function listVersions() {
	return get<{
		versions: {
			version: string;
			url: string;
			jars: string;
		}[];
	}>('versions');
}

export async function getVersion(version: string) {
	return get<{
		version: string;
		jars: string;
		software: {
			software: string;
			url: string;
			jars: string;
		}[]
	}>(`versions/${version}`);
}

export async function getVersionJars(version: string) {
	return get<{
		version: string;
		jars: Jar[];
	}>(`versions/${version}/jars`);
}

export async function getJar(identifier: string) {
	return get<Jar>(`jar/${identifier}`);
}
