<script lang="ts">
	import { Route } from "svelte-routing";
	import DynamicComponent from "./DynamicComponent.svelte";
	import type { DynamicImportMeta } from "../types/DynamicImportMeta";
	import type { Layout } from "../layout/layout-context/layout-context";

	export let routes: RouterRecord[];

	const dynamicRoutes: Record<string, DynamicImportMeta> = {}
	function registerDynamicRoute(path: string, loader: Function | RouterRecord[], layout?: Layout) {
		if (typeof loader === "function") {
			dynamicRoutes[path] = {
				loader: loader,
				component: undefined,
				layout: layout,
			};
			return;
		}
		else if (Array.isArray(loader)) {
			loader.forEach(x => registerDynamicRoute(path + x[0], x[1], (x[2] as Layout | undefined) ?? layout));
		}
	}

	routes.forEach(x => registerDynamicRoute(x[0],x[1], x[2] as Layout | undefined));
</script>

{#each Object.keys(dynamicRoutes) as dynamicRoutePath}
	<Route
		path={dynamicRoutePath}
		component={DynamicComponent}
		dynamic={dynamicRoutes[dynamicRoutePath]}
	/>
{/each}
