<script lang="ts">
	import Loading from "./Loading.svelte";

	export let text = "";
</script>

<div class="loading">
	<Loading />
	{#if text.length > 0}
		<span class="loading-text">{text}</span>
	{/if}
</div>

<style>
	.loading {
		background: #18191b;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 1rem;
	}
	.loading-text {
		color: var(--var-color-text-light);
		font-size: 1.2rem;
		margin-left: 0.5rem;
	}
</style>
