import { writable } from "svelte/store";
import { type ThemeList } from "../../src/XtermThemes";

const defaultDeviceSettings = {
	pinServer: false,
	language: 'cs',
	serverStopAsk: true,
	serverRestartAsk: true,
	serverKillAsk: true,
	xtermTheme: 'helkor' as ThemeList,
};

export const localDeviceSettings = (function() {
	let deviceSettings = defaultDeviceSettings;
	const loadedSettings = localStorage.getItem("deviceSettings");
	try {
		if (loadedSettings) {
			Object.assign(deviceSettings, JSON.parse(loadedSettings));
		}
	} catch (e) {
		console.error("Error loading device settings", e);
	}

	const deviceSettingsStore = writable(deviceSettings);

	deviceSettingsStore.subscribe((value) => {
		localStorage.setItem("deviceSettings", JSON.stringify(value));
	});

	return deviceSettingsStore;
})();
