<script lang="ts">
	import { Link } from "svelte-routing";

	export let icon: string | null = "fa-solid fa-arrow-right";
	export let text = "-";
	export let badge: string | undefined = undefined;
	export let href = "#";
	export let compact = false;
	export let exact = false;
	export let nativeLink = false;
	export let action: undefined | (() => void) = undefined;

	interface GetPropsFuctionArguments {
		href: string,
		isPartiallyCurrent: boolean,
		isCurrent: boolean
	}

	const getProps = ({ href, isPartiallyCurrent, isCurrent }: GetPropsFuctionArguments) => {
		const isActive =
			href === "/" ? isCurrent : (isPartiallyCurrent && !exact) || isCurrent;
		return isActive ? { class: "nav-link active" } : { class: "nav-link"};
	};
</script>

<div class="show-slow nav-item" class:compact={compact}>
	{#if action}
		<button class="nav-link" on:click|preventDefault={action}>
			{#if icon}
				<i class={'nav-icon ' + icon}></i>
			{/if}
			<p>{text}
				{#if badge}
					<span class="badge badge-info right">{badge}</span>
				{/if}
			</p>
		</button>
	{:else if nativeLink}
		<a class="nav-link" {href}>
			{#if icon}
				<i class={'nav-icon ' + icon}></i>
			{/if}
			<p>{text}
				{#if badge}
					<span class="badge badge-info right">{badge}</span>
				{/if}
			</p>
		</a>
	{:else}
		<Link getProps={getProps} to={href} >
			{#if icon}
				<i class={'nav-icon ' + icon}></i>
			{/if}
			<p>{text}
				{#if badge}
					<span class="badge badge-info right">{badge}</span>
				{/if}
			</p>
		</Link>
	{/if}
</div>

<style lang="scss">
	.badge-info {
		background-color: var(--var-color-primary);
		position: absolute;
		right: 1rem;
		top: 0.7rem;
		color: #fff;
	}
	.badge {
		display: inline-block;
		padding: .5em .4em;
		font-size: 75%;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		border-radius: .25rem;
		transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
		transform: translateY(-0.25rem);
	}

	:global(.nav-link) p {
		transition: margin-left .3s linear,opacity .3s ease,visibility .3s ease;
		display: inline-block;
		margin: 0;
		box-sizing: border-box;
		font-size: 1rem;
	}
	.nav-icon {
		font-size: 1.1rem;
		margin-left: 0.05rem;
		margin-right: 0.2rem;
		text-align: center;
		width: 1.6rem;
	}
	.nav-item {
		margin-bottom: 0;
	}
	:global(.nav-link) {
		color: #ced4da;
		position: relative;
		margin-bottom: 0.2rem;
		display: block;
		border-radius: 0.25rem;
		padding: 0.5rem 1rem;
		text-decoration: none;
		background-color: transparent;
		box-sizing: border-box;
		transition: all 0.3s;
		white-space: nowrap;
		overflow: hidden;
		cursor: pointer;
		border: none;
		width: 100%;
		text-align: left;
	}

	.compact :global(.nav-link) {
		padding: 0 0.5rem;
		margin: 0;
		background-color: transparent !important;
		box-shadow: none !important;
		font-size: 1rem;
		overflow: visible;
	}
	.compact :global(.nav-link):hover {
		background-color: transparent;
	}
	i {
		transition: all 0.3s;
	}


	:global(.nav-link.active) {
		background-color: var(--var-color-primary);
		color: white;
		box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
	}
	:global(.nav-link.active) .badge-info {
		background-color: var(--var-color-background);
	}
	:global(.nav-link):not(.active):hover {
		color: white;
		background-color: #2d2e2f;
	}
	:global(.nav-link):not(.active):active {
		transition: all 0.1s;
		color: white;
		background-color: lighten(#2d2e2f, 10%);
	}

	@media all and (max-width: 1200px) and (min-width: 768px)
	{
		:global(.main-sidebar:not(.main-sidebar:hover) .nav-link) {
			padding: 0.5rem 0;
			overflow: hidden;
			height: 2.25rem;
			white-space: nowrap;
			width: fit-content;
		}

		:global(.main-sidebar:not(.main-sidebar:hover) .nav-link) i {
			margin-left: 0.5rem;
			white-space: nowrap;
		}

		:global(.main-sidebar:not(.main-sidebar:hover) .nav-link) p {
			opacity: 0;
			width: 0;
			white-space: nowrap;
		}
	}

</style>
