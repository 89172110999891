<script lang="ts">
	import { onMount, onDestroy } from 'svelte';
	import Loading from './Loading.svelte';
  import { t } from '../lib/locale';

	onMount(()=>{
		window.addEventListener('online',onOnline);
		window.addEventListener('offline',onOffline);
		checkInterval = setInterval(checkStatus,10*1000);
	});
	onDestroy(()=>{
		window.removeEventListener('online', onOnline);
		window.removeEventListener('offline', onOffline);
		clearInterval(checkInterval);
	});

	let isOnline = true;
	let checkInterval: NodeJS.Timeout;

	let onOnline = () => {
		isOnline = true;
	};
	let onOffline = () => {
		isOnline = false;
	};
	let checkStatus = () => {
		if (navigator.onLine != isOnline)
			isOnline = navigator.onLine;
	}
</script>

{#if !isOnline}
<div class="no-internet">
	{t('component.internetConnectionGuard.offline', 'OFFLINE - Vaše zařízení není připojeno k internetu. Prosím připojte zařízení k internetu.')}
</div>
{/if}

<style>
	.no-internet {
		width: 100%;
		background-color: #f44336;
		box-sizing: border-box;
		padding: .5rem;
		color: white;
		border-radius: .3rem;
	}
</style>
