<script lang="ts">
	import { User } from "../../store";
	import { t } from "../../lib/locale";
	import { ln2br } from "../../utils/formatString";

	export let min: number;
	export let userId: number | undefined;
</script>

<h2>{t('order.field.payment.title')}</h2>
<p>
	{@html ln2br(t('order.field.payment.description'))}
</p>

{#if !$User || !userId}
	<p class="error">
		<i class="fas fa-exclamation-triangle"></i>
		{t('order.field.payment.needLogin')}
	</p>
{:else if $User.id !== userId && $User.role === "admin"}
	<p class="ok">
		<i class="fas fa-check"></i>
		{t('order.field.payment.adminInfo')}
	</p>
{:else if $User.credits < min}
	<p class="error">
		<i class="fas fa-exclamation-triangle"></i>
		{@html ln2br(t('order.field.payment.notEnoughCredits'))}
		<br />
		<br />
		<a class="button secondary" target="_blank" href="/credit">
			<i class="fas fa-coins"></i>
			{t('order.field.payment.addCredits')}
		</a>
	</p>
{:else}
	<p class="ok">
		<i class="fas fa-check"></i>
		{t('order.field.payment.enoughCredits')}
	</p>
{/if}


<style>
	p {
		color: var(--var-color-text-light);
	}

	.error {
		color: #ff2c2c;
	}
	.ok {
		color: #388e3c;
	}
</style>
