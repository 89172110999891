<script lang="ts">
	// Functionality imports
	import { Layout, currentLayout } from "./layout-context";
	// Layout imports
	import LayoutEmpty from "./layouts/LayoutEmpty.svelte";
	import LayoutLogin from "./layouts/LayoutLogin.svelte";
	import LayoutMain from "./layouts/LayoutMain.svelte";
	import LayoutAnonymous from "./layouts/LayoutAnonymous.svelte";

	// Layout mappings
	type LayoutComponent = any;
	const layouts: Record<Layout, LayoutComponent> = {
		[Layout.NONE]: LayoutEmpty,
		[Layout.MAIN]: LayoutMain,
		[Layout.LOGIN]: LayoutLogin,
		[Layout.ANONYMOUS]: LayoutAnonymous,
	}

	let selectedLayout: LayoutComponent = layouts[Layout.NONE];
	currentLayout.subscribe((layout) => {
		selectedLayout = layouts[layout];
	});
</script>
<svelte:component this={selectedLayout}>
	<slot />
</svelte:component>
