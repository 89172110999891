type EffectTarget = HTMLElement | null | undefined;
type EffectFunction = (element: EffectTarget) => Promise<void>;

async function runEffectWithClass(element: EffectTarget, effectClass: string, duration: number) {
	if (!element) {
		return;
	}
	if (element.classList.contains('effect')) {
		return;
	}

	element.classList.add('effect');
	element.classList.add(effectClass);
	await new Promise((resolve) => setTimeout(resolve, duration));

	if (!element) {
		return;
	}
	element.classList.remove('effect');
	element.classList.remove(effectClass);
}

export function createEffectNode(effect: EffectFunction) {
	let effectTarget: EffectTarget;
	function bind(targetNode: HTMLElement) {
		effectTarget = targetNode;

		return {
			destroy() {
				effectTarget = undefined;
			},
		};
	}
	async function run() {
		await effect(effectTarget);
	}

	return {
		bind,
		run,
	}
}
export function createEffectProxy(effect: EffectFunction) {
	const effectNode = createEffectNode(effect);
	return (targetNode: EffectTarget = undefined) => {
		if (targetNode) {
			effectNode.bind(targetNode);
		} else {
			effectNode.run();
		}
	}
}

export async function shakeEffect(element: EffectTarget) {
	await runEffectWithClass(element, "shake", 500);
}
