import { writable } from "svelte/store";
import Api from "../Api";
import { User } from "../store";
import { error, success } from "../toaster";

let _lastEmailTry: number = 0;
export const lastEmailTry = writable(0);
lastEmailTry.subscribe(value => _lastEmailTry = value);


export async function verifyEmail()
{
	if (Date.now() - _lastEmailTry < 10 * 60 * 1000)
	{
		error("Ne tak rychle! Můžete odeslat požadavek jen jednou za 10 minut.");
		return;
	}
	lastEmailTry.set(Date.now());

	success("Ověřovací e-mail byl odeslán do vaší schránky");

	try {
		const response = await Api.call('settings/verify-email');
		if (!response.success)
		{
			error(response.message ?? "Chyba při odesílání emailu");
		}
	} catch (e) {
		error("Nastala neočekávaná chyba");
	} finally {
		User.fetch();
	};
}
