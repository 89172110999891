<script lang="ts">
	export let value: string | null;
	export let title: string;
	export let icon: string;
	export let recommended: boolean = false;
	export let groupIdentifier: string | undefined;
</script>

<div class="version" class:active={value?.split('-')[0] === groupIdentifier}>
	<div class="info">
		<img class="info-icon" alt={title} src={icon} />
		<div class="info-title">
			{title}
		</div>
		{#if recommended}
			<div class="info-recommended">
				<i class="fa-solid fa-star"></i>
				Doporučeno
			</div>
		{/if}
	</div>
	<select bind:value={value}>
		<slot />
	</select>
</div>

<style>
	.version {
		border: 1px solid var(--var-color-border);
		padding: .5rem;
		border-radius: 0.25rem;
		background-color: var(--var-color-border);
	}
	.version.active {
		border-color: var(--var-color-success);
	}

	.info {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: .75rem;
		margin-bottom: .5rem;
	}
	.info-icon {
		width: 2rem;
		height: 2rem;
		object-fit: scale-down;
	}
	.info-recommended {
		color: var(--var-color-text-light);
		font-size: .75rem;
		margin-top: .3rem;
		margin-left: auto;
	}
	.info-recommended i {
		margin-right: .15rem;
		transform: translateY(-0.05rem);
	}
</style>
