<script lang="ts">
	let helpLink = "https://helkor.eu/help";
	let show = false;
	function openHelp() {
		// show = true;
		window.open(helpLink, "_blank");
	}
	function onKeyDown(event: KeyboardEvent) {
		if (event.key === "F1" && !show) {
			event.preventDefault();
			openHelp();
		}
		else if (event.key === "F1" || event.key === "Escape") {
			event.preventDefault();
			show = false;
		}
	}
</script>

<!-- <SidebarModal bind:open={show}>
	<GuideList />
</SidebarModal> -->

<svelte:window on:keydown={onKeyDown} />
