import { ClientJobState, type ClientJobProgress, type ClientJob, type ClientJobCallback } from "../../types/ClientJob.type";

export class DummyJob implements ClientJob {
	private _progress: ClientJobProgress = {
		state: ClientJobState.QUEUED,
		percent: 0,
		title: "Dummy Job",
		cancelable: true,
		message: "This is a dummy job.",
	}
	private _callback: ClientJobCallback | undefined = undefined;
	private _cancelCallback: undefined | (() => boolean) = undefined;
	private _runCallback: undefined | (() => boolean) = undefined;

	private updateProgress() {
		if (this._callback) {
			this._callback(this._progress);
		}
	}

	public run() {
		const running = this._runCallback ? this._runCallback() : true;
		if (!running) {
			this._progress.state = ClientJobState.FAILED;
			this.updateProgress();
			return;
		}

		this._progress.state = ClientJobState.RUNNING;
		this.updateProgress();
	}

	public set state(state: ClientJobState) {
		this._progress.state = state;
		this.updateProgress();
	}
	public get state(): ClientJobState {
		return this._progress.state;
	}

	public set percent(percent: number) {
		this._progress.percent = percent;
		this.updateProgress();
	}
	public get percent(): number {
		return this._progress.percent;
	}

	public set title(title: string) {
		this._progress.title = title;
		this.updateProgress();
	}
	public get title(): string {
		return this._progress.title;
	}

	public set message(message: string) {
		this._progress.message = message;
		this.updateProgress();
	}
	public get message(): string {
		return this._progress.message;
	}

	public set cancelable(cancelable: boolean) {
		this._progress.cancelable = cancelable;
		this.updateProgress();
	}

	public set onCancel(callback: () => boolean) {
		this._cancelCallback = callback;
	}
	public set onRun(callback: () => boolean) {
		this._runCallback = callback;
	}

	cancel() {
		if (this._progress.cancelable) {
			const canceled = this._cancelCallback ? this._cancelCallback() : true;
			if (!canceled) {
				return;
			}

			this._progress.state = ClientJobState.CANCELED;
			this.updateProgress();
		}
	}
	getProgress() {
		return this._progress;
	}

	setOnProgressCallback(callback: ClientJobCallback) {
		this._callback = callback;
	}
}
