<script lang="ts">
	import Api from "../Api";
	import Loading from "../layout/Loading.svelte";
	import { Layout, setLayout } from "../layout/layout-context/layout-context";
	import type { DynamicImportMeta } from "../types/DynamicImportMeta";
	import { timeMonitor } from "./time-monitor";

	export let dynamic: DynamicImportMeta;
	let loadingError = false;

	async function loadComponent() {
		timeMonitor.start("dynamic-component-load");
		loadingError = false;

		try {
			const module = await dynamic.loader();
			dynamic.component = module.default;
		} catch (e) {
			loadingError = true;

			if (Api.environment.debug) {
				throw e;
			} else {
				window.location.reload();
			}
		} finally {
			timeMonitor.stop("dynamic-component-load");
		}
	}

	function reloadApp() {
		location.reload();
	}

	if (!dynamic.component) {
		loadComponent();
	}

</script>

{#if dynamic.component}
	{@const x = setLayout(dynamic.layout ?? Layout.MAIN)}
	<svelte:component this={dynamic.component} {...$$restProps} />
{/if}

{#if !dynamic.component && !loadingError}
	<Loading />
{/if}

{#if loadingError}
	<div class="dynamic_module_error">
		<h3>Nelze načíst modul</h3>
		<p>
			Omlouváme se, ale nepodařilo se nám načíst tento modul. <br />
			Pravděpodobně se tak stalo kvůli novější verzi, která je nyní nainstalována na serveru. <br />
			Prosím, restartujte aplikaci pro načtení nové verze aplikace.
		</p>
		<button class="button small" on:click={reloadApp}>
			<i class="fa-solid fa-rotate-right"></i>
			Restartovat aplikaci
		</button>
	</div>
{/if}
