import Api from "../Api";

const timings = new Map<string, number>();
export const timeMonitor = {
  start(label: string) {
	if (!Api.environment.debug) return;

	timings.set(label, performance.now());
  },
  stop(label: string) {
	if (!Api.environment.debug) return;

	const start = timings.get(label);
	if (start === undefined) {
		console.warn(`Label "${label}" not found`);
		return;
	}
	const end = performance.now();
	timings.delete(label);
	console.log(`Time: ${label} took ${end - start}ms`);
  },
  isRunning(label: string) {
	return timings.has(label);
  }
};
