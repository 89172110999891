<script lang="ts">
	import { onMount } from "svelte";
	import { navigate } from "svelte-routing";
	export let to = "/";

	onMount(() => {
		setTimeout(() => {
			navigate(to);
		}, 0);
	});
</script>
