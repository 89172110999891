export function formatString(text: string, args: Record<string, string>) {
	return text.replace(/{(\w+)}/g, function (match, key) {
		return args[key] || match;
	});
}

export const f = formatString;

export const escapeHtml = (unsafe: string) => {
	return unsafe.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');
}

export function ln2br(text: string, escape: boolean = true) {
	if (escape) {
		text = escapeHtml(text);
	}
	return text.replace(/\n/g, '<br>');
}
