<script lang="ts">
	import { User }	from '../store';
	import { lastEmailTry, verifyEmail } from '../lib/email-verify';

	export let title = 'Nemáte ověřený e-mail';
	export let description = "Prosím ověřte svojí e-mail adresu, aby jste mohli využívat všechny funkce našeho hostingu.\nOvěření zabere jen chvilku a zlepší bezpečnost vašeho účtu.";
	export let buttonText = 'Ověřit e-mail';
</script>

{#if $User && !$User.email_verified && !$lastEmailTry}
<p class="warning">
	<b>{title}</b>
	<br />
	{#each description.split('\n') as line}
		<div>{line}</div>
	{/each}
	<button class="button small warn" on:click={verifyEmail}>
		<i class="fa fa-envelope"></i>
		{buttonText}
	</button>
</p>
{/if}
