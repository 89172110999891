export function sortVersions(a: string, b: string) {
	const aParts = a.split('.').map((n) => parseInt(n));
	const bParts = b.split('.').map((n) => parseInt(n));

	for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
		const aPart = aParts[i] ?? 0;
		const bPart = bParts[i] ?? 0;

		if (aPart < bPart) {
			return -1;
		} else if (aPart > bPart) {
			return 1;
		}
	}

	return 0;
}
