import { writable } from "svelte/store";
import { ClientJobState, type ClientJob, type ClientJobProgress } from "../../types/ClientJob.type";

export const clientJobs = writable<ClientJob[]>([]);

export class JobManager {

	private static _jobs: ClientJob[] = [];

	public static async runJob(job: ClientJob) {
		this.registerJob(job);
		job.run();
	}

	public static async runJobPromise(job: ClientJob): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			this.runJob(job);
			const defaultProgressCallback = this.generateProgressCallback(job);
			job.setOnProgressCallback(progress => {
				defaultProgressCallback(progress);
				if (progress.state === ClientJobState.FINISHED) {
					resolve(true);
				} else if (progress.state === ClientJobState.CANCELED) {
					resolve(false);
				} else if (progress.state === ClientJobState.FAILED) {
					reject();
				}
			});
		});
	}

	public static async registerJob(job: ClientJob) {
		if (this._jobs.includes(job)) {
			return;
		}
		this._jobs.push(job);
		job.setOnProgressCallback(this.generateProgressCallback(job));
		clientJobs.set(this._jobs);
	}

	private static generateProgressCallback = (job: ClientJob) => (progress: ClientJobProgress) => {
		if (progress.state === ClientJobState.FINISHED || progress.state === ClientJobState.CANCELED || progress.state === ClientJobState.FAILED) {
			this._jobs = this._jobs.filter(j => j !== job);
		}
		clientJobs.set(this._jobs);
	}
}
