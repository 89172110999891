import md5 from "md5";

export default class ProfilePictureManager {

	static getAvatar(user: User): string
	{
		const fallbackString = user.username.substring(0,2);
		const fallbackUrl = this.initial(fallbackString, 'png');
		return user.avatar ?? this.gravatar(user.email as string, 128, fallbackUrl) ;
	}

	// Types
	static initial(username: string, format = 'svg') {
		return this.dicebear('initials', username, format);
	}

	static identicon(email: string, format = 'svg') {
		let hash = md5(email.toLowerCase().trim());
		return this.dicebear('identicon', hash, format);
	}

	static persona(email: string, format = 'svg'){
		let hash = md5(email.toLowerCase().trim());
		return this.dicebear('personas', hash, format);
	}

	static avataaar(email: string, format = 'svg') {
		let hash = md5(email.toLowerCase().trim());
		return this.dicebear('avataaars', hash, format);
	}

	static minecraft(username: string, size = 32) {
		return this.mcheads(username, size);
	}

	// Providers
	static dicebear(type: string, data: any, format = 'svg', fallback = "https://dash.helkor.eu/img/icon.png") {
		if (!data) return fallback;
		return `https://api.dicebear.com/7.x/${type}/${format}/seed=${encodeURIComponent(data)}`;
	}

	static gravatar(email: string, size = 128, fallback?: string) {
		if (!email) return fallback as string;
		return "https://www.gravatar.com/avatar/" + md5(email.toLowerCase().trim()) + "?s=" + size + (fallback ? "&d=" + encodeURIComponent(fallback) : "");
	}

	static mcheads(username: string, size = 32) {
		return `https://mc-heads.net/avatar/${username}/${size}x${size}`;
	}
}
